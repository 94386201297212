<template>
  <div>
    <slot name="checkbox" />
    <fieldset v-if="isSelected" class="ml-10">
      <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.inApp.info") }}</h5>
      <v-autocomplete
        v-model="recipientUsers"
        :filter="filterRecipient"
        :hide-no-data="!recipientUserSearch"
        :items="factoryUsers"
        :search-input.sync="recipientUserSearch"
        :error-messages="userErrorMessages"
        :rules="recipientUserRules"
        :label="$t('alerts.wizard.deliveryMode.inApp.inputPlaceholder')"
        item-value="id"
        item-text="display_name"
        hide-selected
        multiple
        small-chips
        filled
        clearable
        dense
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
            <span class="pr-2">{{ item.display_name }}</span>
            <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
          </v-chip>
        </template>
      </v-autocomplete>
    </fieldset>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InAppDeliveryMode",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    alert: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recipientUserSearch: null,
      userErrorMessages: [],
      recipientUserRules: [
        (v) => !!v || this.$t("alerts.wizard.deliveryMode.inApp.mandatoryMessage"),
        (v) => v.length > 0 || this.$t("alerts.wizard.deliveryMode.inApp.mandatoryMessage"),
      ],
    };
  },
  computed: {
    ...mapGetters("navigation", ["factoryUsers"]),
    recipientUsers: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    filterRecipient(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    initializeData() {
      this.recipientUsers = this.alert?.in_app_user_ids ?? [];
    },
  },
};
</script>