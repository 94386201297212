<template>
  <fragment>
    <wx-form-container class="wizard">
      <v-form ref="userForm" lazy-validation>
        <fieldset>
          <legend class="legend-header top-legend">{{ $t("user.wizard.identification") }}</legend>
          <!-- - - - - - - - - - - - -     Display Name     - - - - - - - - - - - - - - - -->
          <v-row>
            <v-col cols="12" class="mb-0 pb-0">
              <wx-text-field
                v-model.trim="displayName"
                class="huge-field-width single-field-row"
                :rules="[() => validateDisplayName()]"
                :label="$t('user.displayName') + ' *'"
                :counter="displayNameMaxLength"
                :maxlength="displayNameMaxLength"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <wx-text-field
                v-model.trim="email"
                class="huge-field-width single-field-row"
                :disabled="isEdition()"
                :rules="[() => validateEmail()]"
                :label="$t('user.email')"
                :counter="emailMaxLength"
                :maxlength="emailMaxLength"
              />
            </v-col>
          </v-row>
        </fieldset>

        <fieldset v-if="isCreation()">
          <legend class="legend-header">{{ $t("user.password") }}</legend>
          <!-- - - - - - - - - -     Password     - - - - - - - - - - - -->
          <v-row>
            <v-col cols="12" class="mb-0 pb-0 mt-3 pt-0">
              <wx-text-field
                v-if="isCreation()"
                v-model.trim="password"
                :append-icon="showPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[() => validatePassword()]"
                :type="showPasswordValue ? 'text' : 'password'"
                :hint="$t('user.wizard.passwordHint')"
                counter
                @click:append="showPasswordValue = !showPasswordValue"
              >
                <template #label>
                  {{ $t("user.password") }}
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </wx-text-field>
            </v-col>
          </v-row>
        </fieldset>

        <fieldset class="mt-2">
          <legend class="legend-header top-legend">
            {{ $t("user.wizard.roleAndAccess") }}
            <role-description-popup />
          </legend>
          <!-- - - - - - - - - - - - -     Role    - - - - - - - - - - - - - - - -->
          <v-row>
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <wx-autocomplete
                v-model="role"
                :items="getAvailableRoles()"
                item-text="text"
                item-value="value"
                return-object
                :label="$t('user.role') + ' *'"
                :rules="[() => validateRole()]"
                validate-on-blur
                @change="onRoleChange"
              />
            </v-col>
            <!-- - - - - - - - - - - - -     Is Admin     - - - - - - - - - - - - - - - -->
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <v-switch
                v-if="enableAdminSelection"
                v-model="isAdmin"
                inset
                :label="$t('user.isAdmin')"
                class="pt-0 pb-0 mt-0 mb-0"
                @change="onAdminRightChange"
              />
            </v-col>
            <!-- - - - - - - - -    Factory (for operation + admin)    - - - - - - - - -->
            <v-col v-if="enableFactorySelection" cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <span class="wx-typo-sm font-weight-light">{{ $t("user.wizard.operationAndAdminInfo") }}</span>
              <wx-select
                v-if="enableFactorySelection"
                v-model="selectedFactory"
                :items="availableFactories"
                item-value="id"
                item-text="name"
                return-object
                :label="$t('user.wizard.factory') + ' *'"
                :rules="[() => validateSelectedFactory()]"
                class="mt-2"
              />
            </v-col>
            <!-- - - - - - - - - - - - -     Is Financial Admin     - - - - - - - - - - - - - - - -->
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <v-switch
                v-if="enableFinancialAdminSelection"
                v-model="isFinancialAdmin"
                inset
                :label="$t('user.isFinancialAdmin')"
                class="pt-0 pb-0 mt-0 mb-0"
              />
            </v-col>
            <!-- - - - - - - - - -      Tilelytics Access    - - - - - - - - - - - - - - - -->
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <v-switch
                v-if="enableTilelyticsAccessSelection"
                v-model="isTilelytics"
                inset
                :disabled="!isTilelyticsForLoggedInAdmin"
                :label="$t('user.isTilelytics')"
                class="pt-0 pb-0 mt-0 mb-0"
              />
            </v-col>
          </v-row>
        </fieldset>

        <fieldset v-if="!enableFactorySelection && enableProductionUnitSelection" class="mt-2">
          <legend class="legend-header top-legend">{{ $t("user.wizard.productionUnits") }}</legend>

          <!-- - - - - - - - - - -     Production Unit(s)     - - - - - - - - - - - - - - - -->
          <v-row v-if="enableProductionUnitSelection">
            <v-col cols="12" class="pt-0 mt-0">
              <wx-production-unit-multi-selector
                v-model="selectedProductionUnits"
                :is-multiple-factories="isMultipleFactories"
                :available-items="sortedProductionUnits"
              />
            </v-col>
          </v-row>
        </fieldset>

        <!-- - - - - - - - - - - - -     Is Active     - - - - - - - - - - - - - - - -->
        <fieldset class="mt-2">
          <legend class="legend-header top-legend">{{ $t("user.status") }}</legend>
          <v-row>
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <v-switch v-model="isActive" inset :label="$t('user.isActive')" />
            </v-col>
          </v-row>
        </fieldset>

        <form-footer-actions :click-submit="submit" cancel-to="toUsers" />
      </v-form>
    </wx-form-container>
  </fragment>
</template>

<script>
import ErrorHandling from "@/components/ErrorHandling";
import FormFooterActions from "@/components/ui/FormFooterActions";
import RouteService from "@/router/RouteService";
import UserService from "@/components/user/UserService";
import Validations from "@/components/validations";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import WxFormContainer from "@/components/ui/WxFormContainer";
import WxTextField from "@/components/ui/WxTextField";
import { mapActions, mapGetters } from "vuex";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector";
import { DIRECTOR_ROLE, SUPERVISOR_ROLE } from "@/store/modules/user";
import RoleDescriptionPopup from "@/components/user/RoleDescriptionPopup";
import WxSelect from "@/components/ui/WxSelect";
import { compareName } from "@/components/SortUtils";

export default {
  name: "UserWizard",
  components: {
    WxSelect,
    RoleDescriptionPopup,
    FormFooterActions,
    WxAutocomplete,
    WxFormContainer,
    WxTextField,
    WxProductionUnitMultiSelector,
  },
  props: {
    value: Boolean,
    initialUser: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      id: null,
      displayName: null,
      email: null,
      password: null,
      showPasswordValue: false,
      role: null,
      selectedFactory: null,
      selectedProductionUnits: [],
      isActive: true,

      enableAdminSelection: false,
      enableFactorySelection: false,
      enableFinancialAdminSelection: false,
      enableTilelyticsAccessSelection: false,
      enableProductionUnitSelection: true,

      isAdmin: false,
      isFinancialAdmin: false,

      isTilelytics: false,

      displayNameMaxLength: Validations.userDisplayNameMaxLength,
      emailMaxLength: Validations.userEmailMaxLength,
      passwordMaxLength: Validations.userPasswordMaxLength,
      maxNumberOfChips: 6,
    };
  },
  watch: {
    initialUser() {
      if (this.initialUser) {
        this.id = this.initialUser.id;
        this.displayName = this.initialUser.display_name;
        this.email = this.initialUser.email;

        this.role = this.availableRoles.find((r) => r.value === this.initialUser.role);
        this.onRoleChange();

        this.isAdmin = this.initialUser.is_admin;
        this.onAdminRightChange();

        this.isFinancialAdmin = this.initialUser.is_financial_admin;
        this.isTilelytics = this.initialUser.is_tilelytics;
        this.isActive = this.initialUser.status === "active";

        this.selectedFactory = null;
        if (this.isOperation() && this.isAdmin) {
          const puId = this.initialUser.production_unit_ids.first;
          const factory = this.factories.find((f) => f.productionUnits.id === puId);
          this.selectedFactory = {
            id: factory.id,
            name: factory.name,
          };
        } else {
          let puList = [];
          this.initialUser.production_unit_ids.forEach((puId) => {
            const pu = this.availableProductionUnits.find((pu) => pu.id === puId);
            if (pu) {
              puList.push(pu);
            }
          });
          this.selectedProductionUnits = puList;
        }
      }
    },
  },
  computed: {
    ...mapGetters("user", [
      "factories",
      "isMultipleFactories",
      "availableProductionUnits",
      "loggedInUserRole",
      "isTilelyticsEnabled",
    ]),
    isTilelyticsForLoggedInAdmin() {
      // Return `true` if the current admin (the one who's editing/creating a user) has access to Tilelytics.
      // This value is not about the settings of the users being crated/edited! It's all about the one who's doing it!
      return this.isTilelyticsEnabled;
    },
    availableRoles() {
      return UserService.getAvailableRoles();
    },
    availableFactories() {
      return this.factories.map((f) => ({
        id: f.id,
        name: f.name,
      }));
    },
    sortedProductionUnits() {
      // Sort by Factory. Then, within a factory, sort by name
      let puByFactory = new Map();

      // Group production units by factory
      this.availableProductionUnits.forEach((pu) => {
        let puList = puByFactory.get(pu.factory);
        if (puList) {
          puList.push(pu);
          puList.sort(compareName);
          puByFactory.set(pu.factory, puList);
        } else {
          puList = [];
          puList.push(pu);
          puByFactory.set(pu.factory, puList);
        }
      });
      // Here, each factory has a sorted list of its production units
      // Now sort by the map's key (the factory name)
      puByFactory = new Map([...puByFactory].sort());

      let completePulist = [];
      puByFactory.forEach((puForFactory) => completePulist.push(...puForFactory));
      return completePulist;
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    getRouteBack() {
      return RouteService.toUsers();
    },
    getAvailableRoles() {
      if (this.loggedInUserRole === DIRECTOR_ROLE) {
        return this.availableRoles;
      } else {
        return [UserService.getOperationRoleOption()];
      }
    },
    onRoleChange() {
      if (this.isPresentor()) {
        this.isAdmin = false;
        this.isFinancialAdmin = false;
        this.isTilelytics = false;
        this.enableAdminSelection = false;
        this.enableFactorySelection = false;
        this.enableFinancialAdminSelection = false;
        this.enableTilelyticsAccessSelection = false;
        this.enableProductionUnitSelection = true;
      }
      if (this.isOperation()) {
        this.isAdmin = false;
        this.isFinancialAdmin = false;
        this.isTilelytics = false;
        this.enableAdminSelection = true;
        this.enableFactorySelection = false;
        this.enableFinancialAdminSelection = false;
        this.enableTilelyticsAccessSelection = true;
        this.enableProductionUnitSelection = true;
        return;
      }
      if (this.isManagement()) {
        this.isAdmin = true;
        this.isFinancialAdmin = true;
        this.isTilelytics = true;
        this.enableAdminSelection = false;
        this.enableFactorySelection = false;
        this.enableFinancialAdminSelection = false;
        this.enableTilelyticsAccessSelection = false;
        this.enableProductionUnitSelection = false;
      }
    },
    onAdminRightChange() {
      if (this.isOperation()) {
        if (this.isAdmin) {
          this.enableFactorySelection = true;
          this.enableProductionUnitSelection = false;
        } else {
          this.enableFactorySelection = false;
          this.enableProductionUnitSelection = true;
        }
      }
    },
    validateDisplayName() {
      if (!this.displayName) {
        return this.$t("user.errors.noDisplayName");
      }
      if (this.displayName.length > this.displayNameMaxLength) {
        return this.$t("user.errors.displayNameTooLong", { maxLength: this.displayNameMaxLength });
      }
      if (!Validations.isUserDisplayNameValid(this.displayName)) {
        return this.$t("user.errors.displayNameHasInvalidChar");
      }
      return true;
    },
    validateEmail() {
      if (!this.email) {
        return this.$t("user.errors.noEmail");
      }
      if (this.email.length > this.emailMaxLength) {
        return this.$t("user.errors.emailTooLong", { maxLength: this.emailMaxLength });
      }
      if (!Validations.isUserEmailValid(this.email)) {
        return this.$t("user.errors.invalidEmail");
      }
      return true;
    },
    validatePassword() {
      if (!this.password || this.password.length === 0) {
        return this.$t("user.errors.noPassword");
      }
      if (!Validations.isUserPasswordValid(this.password)) {
        return this.$t("user.errors.invalidPassword");
      }
      if (this.password.length > this.userPasswordMaxLength) {
        return this.$t("user.errors.passwordTooLong", { maxLength: this.userPasswordMaxLength });
      }
      return true;
    },
    validateRole() {
      if (!this.role) {
        return this.$t("user.errors.noRole");
      }
      const foundRole = this.availableRoles.find((r) => r.value === this.role.value);
      if (!foundRole) {
        return this.$t("user.errors.invalidRole", { role: this.role });
      }
      return true;
    },
    isPresentor() {
      if (this.role) {
        return UserService.isPresentor(this.role.value);
      }
      return false;
    },
    isOperation() {
      if (this.role) {
        return UserService.isOperation(this.role.value);
      }
      return false;
    },
    isManagement() {
      if (this.role) {
        return UserService.isManagement(this.role.value);
      }
      return false;
    },
    validateSelectedFactory() {
      if (this.enableFactorySelection) {
        if (this.selectedFactory) {
          return true;
        } else {
          return this.$t("user.errors.noFactory");
        }
      }
      return true;
    },
    validateProductionUnits() {
      if (!this.selectedProductionUnits || this.selectedProductionUnits.length === 0) {
        return this.$t("user.errors.noProductionUnits");
      }
      return true;
    },
    isCreation() {
      return this.id === null;
    },
    isEdition() {
      return this.id !== null;
    },
    submit() {
      const isValid = this.$refs.userForm.validate();
      if (isValid) {
        let user = this.buildUser();
        if (this.isCreation()) {
          this.createUser(user);
        }
        if (this.isEdition()) {
          this.updateUser(this.id, user);
        }
      }
    },
    buildUser() {
      let productionUnitIds;
      switch (this.role.value) {
        case SUPERVISOR_ROLE: {
          if (this.selectedFactory && this.isAdmin) {
            const factory = this.factories.find((f) => f.id === this.selectedFactory.id);
            productionUnitIds = factory.productionUnits.map((pu) => pu.id);
          } else {
            productionUnitIds = this.selectedProductionUnits.map((pu) => pu.id);
          }
          break;
        }
        case DIRECTOR_ROLE: {
          productionUnitIds = this.availableProductionUnits.map((pu) => pu.id);
          break;
        }
        default: {
          productionUnitIds = this.selectedProductionUnits.map((pu) => pu.id);
        }
      }
      return {
        email: this.email,
        password: this.isCreation() ? this.password : null,
        display_name: this.displayName,
        role: this.role.value,
        is_admin: this.isAdmin,
        is_financial_admin: this.isFinancialAdmin,
        is_tileplus: true,
        is_tilelytics: this.isTilelytics,
        status: this.isActive ? "active" : "inactive",
        production_unit_ids: productionUnitIds,
      };
    },
    createUser(user) {
      UserService.createUser(user)
        .then((httpResponse) => this.handleCreateUserResponse(httpResponse))
        .catch((error) => this.handleUserEditionErrors(error.response));
    },
    handleCreateUserResponse(httpResponse) {
      if (httpResponse.status === 201) {
        const email = httpResponse.data.email;
        this.showOperationSuccess(this.$t("user.wizard.creation.successfullyCreated", { email: email }));
        this.close();
      }
    },
    updateUser(userId, user) {
      UserService.updateUser(userId, user)
        .then((httpResponse) => this.handleUpdateUserResponse(httpResponse))
        .catch((error) => this.handleUserEditionErrors(error.response));
    },
    handleUpdateUserResponse(httpResponse) {
      if (httpResponse.status === 200) {
        const email = httpResponse.data.email;
        this.showOperationSuccess(this.$t("user.wizard.update.successfullyUpdated", { email: email }));
        this.close();
      }
    },
    handleUserEditionErrors(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.close();
    },
    getErrorMessage(code) {
      switch (code) {
        case "UMS_USER_PUT_1014": {
          return this.$t("user.errors.userNotFound");
        }
        case "UMS_USER_PUT_1030": {
          return this.$t("user.errors.puNotFound");
        }
        case "UMS_USER_POST_10002": {
          return this.$t("user.errors.duplicateEmail", { email: this.email });
        }
        case "UMS_USER_POST_10009": {
          return this.$t("user.errors.unsupportedRoleAdminCombination", {
            role: this.role,
            isAdmin: this.isAdmin,
            isFinancialAdmin: this.isFinancialAdmin,
          });
        }
        default: {
          return this.$t("common.errors.default", { code: code });
        }
      }
    },
    close() {
      this.id = null;
      this.displayName = null;
      this.email = null;
      this.password = null;
      this.showPasswordValue = false;
      this.role = null;
      this.selectedProductionUnits = [];
      this.isActive = true;
      this.isAdmin = false;
      this.isFinancialAdmin = false;
      this.isTilelytics = false;

      this.$refs.userForm.resetValidation();
      this.$router.push(this.getRouteBack());
    },
  },
};
</script>

<style lang="scss" scoped>
legend {
  display: block;
}
</style>
