<template>
  <div class="group-stack">
    <div class="cards">
      <StackedCard
        v-for="(item, index) in displayedItems"
        :key="getItemKey(item, index)"
        :item="item"
        :index="index"
        :show-buttons="showButtons"
        :total-cards="displayedItems.length"
        :raised-effect="isTopStack"
        :primary-action-button-text="primaryActionButtonText"
        :cancel-action-button-text="cancelActionButtonText"
        :cancel-action-callback="cancelActionCallback"
        :primary-action-callback="primaryActionCallback"
        @itemProcessed="handleItemProcessed"
      >
        <template v-slot:default="slotProps">
          <slot v-bind="slotProps"></slot>
        </template>
      </StackedCard>
    </div>
  </div>
</template>

<script>
import StackedCard from "./StackedCard.vue";

export default {
  components: { StackedCard },
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    isTopStack: {
      type: Boolean,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: null,
    },
    primaryActionButtonText: {
      type: String,
      default: "",
    },
    cancelActionButtonText: {
      type: String,
      default: "",
    },
    primaryActionCallback: {
      type: String,
      default: "",
    },
    cancelActionCallback: {
      type: String,
      default: "",
    },
  },
  computed: {
    displayedItems() {
      return this.isTopStack ? this.group.items : [this.group.items[0]];
    },
  },
  methods: {
    handleItemProcessed(action, item) {
      this.$emit("handleItemProcessed", action, item);
    },
    getItemKey(item, index) {
      if (typeof this.itemKey === "function") {
        return this.itemKey(item);
      } else if (typeof this.itemKey === "string") {
        return item[this.itemKey];
      } else {
        return index;
      }
    },
  },
};
</script>

<style scoped>
.group-stack {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 30%;
  height: 100%;
  position: relative;
}
.cards {
  position: relative;
  width: 450px;
  height: 150px;
  overflow: visible;
  margin-bottom: 4px;
}
</style>
