<template>
  <fragment>
    <wx-form-container class="wizard form-style">
      <!-- <v-form ref="productForm" lazy-validation> -->
      <h1 class="main-title">{{ productTitle }}</h1>
      <div class="d-flex flex-column" style="gap: var(--box-padding-admin)">
        <!-- - - - - - - - -       Details Section       - - - - - - - - - - - -->
        <v-card class="product-section-card">
          <v-card-text>
            <v-expansion-panels v-model="detailsAccordionOpen" class="expansion-panel-style" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("product.wizard.details") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="detailsForm" lazy-validation v-model="isDetailsSectionValid">
                    <fieldset>
                      <v-row>
                        <v-col cols="12">
                          <!-- - - - - - - - - - - - -     Sku     - - - - - - - - - - - - - - - -->
                          <wx-text-field
                            v-model.trim="sku"
                            :label="$t('product.details.sku') + ' *'"
                            :rules="[() => validateSku()]"
                            :counter="skuMaxLength"
                            :maxlength="skuMaxLength"
                            :disabled="!isCreation"
                          />
                        </v-col>
                        <v-col cols="12">
                          <!-- - - - - - - - - - - -       Name       - - - - - - - - - - - - - - -->
                          <wx-text-field
                            v-model.trim="name"
                            :rules="[() => validateName()]"
                            :label="$t('common.name')"
                            :counter="productNameMaxLength"
                            :maxlength="productNameMaxLength"
                          />
                        </v-col>
                        <v-col cols="12">
                          <!-- - - - - - - - - - - -     Category     - - - - - - - - - - - - - - -->
                          <wx-text-field
                            v-model.trim="category"
                            :rules="[() => validateCategory()]"
                            :label="$t('product.wizard.category')"
                            :counter="productCategoryMaxLength"
                            :maxlength="productCategoryMaxLength"
                          />
                        </v-col>
                      </v-row>
                    </fieldset>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <!-- - - - - - - - -       Production Units Section       - - - - - - - - - - - -->
        <v-card class="product-section-card">
          <v-card-text>
            <v-expansion-panels class="expansion-panel-style" flat v-model="productionUnitAccordionOpen">
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("productionUnit.title") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="productionUnitsForm" lazy-validation v-model="isProductionUnitSectionValid">
                    <!-- create the production units associated to the current the product -->
                    <ul class="associated-pu-list">
                      <li
                        class="pu-item"
                        v-for="(associatedPU, index) in associatedProductionUnits"
                        :key="'production-unit' + index"
                      >
                        <v-row>
                          <v-col cols="12">
                            <!-- - - - - - - - -     Production Unit Name     - - - - - - - - - - -  -->
                            <header class="legend-header">
                              <h3 class="wx-typo-h2">{{ $t("productionUnit.titleEdition") }} {{ index + 1 }}</h3>
                              <v-chip
                                class="ma-2"
                                color="secondary"
                                x-small
                                v-if="isProductionUnitSelected(associatedPU)"
                                >{{ getPackages(associatedPU) }}</v-chip
                              >
                              <wx-btn-standard
                                class="btn-delete"
                                text
                                @click="deleteLine(index)"
                                :disabled="isProductAssociatedToOneProductionUnit()"
                                :title="$t('product.wizard.deletion.buttonRemoveLineHoverTitle')"
                              >
                                <v-icon left>mdi-close</v-icon>
                                {{ $t("product.wizard.deletion.buttonRemove") }}
                              </wx-btn-standard>
                            </header>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <wx-select
                              v-model="associatedPU.productionUnit"
                              class="huge-field-width single-field-row"
                              :value="associatedPU.productionUnit.id"
                              :items="currentProductionUnits"
                              item-text="name"
                              item-value="id"
                              :label="$t('productionUnit.titleEdition') + ' *'"
                              :rules="[
                                () => isProductionUnitMissing(associatedPU.productionUnit.id),
                                isSelectedProductionUnitDuplicated(associatedPU.productionUnit.id),
                              ]"
                              @change="
                                (selectedProductionUnit) => onSelectedProductionUnit(selectedProductionUnit, index)
                              "
                              return-object
                            />
                          </v-col>
                        </v-row>

                        <section class="convert-factor-objective-container">
                          <!-- - - - - - - -    Product Objective per PU    - - - - - - - - -  -->
                          <fieldset class="inline-fields">
                            <v-row>
                              <v-col>
                                <ul class="objectives-list">
                                  <li v-for="objective in associatedPU.objectives" :key="objective.objectiveType">
                                    <v-row
                                      v-if="
                                        isRateObjective(objective.objectiveType) &&
                                          isRateObjectiveAuthorized(associatedPU.productionUnit.id)
                                      "
                                    >
                                      <v-col cols="6" class="d-flex pt-0 pb-0">
                                        <wx-text-field
                                          v-model="objective.objectiveValue"
                                          type="text"
                                          :label="$t('product.wizard.update.targetRatePerHour')"
                                          :rules="[() => validateRateObjectiveValue(objective.objectiveValue)]"
                                          class="mr-2"
                                        />
                                        <wx-autocomplete
                                          v-model="objective.objectiveValueUnit"
                                          :items="
                                            availableRateObjectiveUnits(isRateObjectiveUnitDisabled(associatedPU))
                                          "
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('product.details.targetUnit')"
                                          :disabled="isRateObjectiveUnitDisabled(associatedPU)"
                                          :rules="[
                                            () => validateRateObjectiveUnit(objective.objectiveValueUnit, associatedPU),
                                          ]"
                                          class="production-objective-unit"
                                        />
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      v-if="
                                        isGiveawayObjective(objective.objectiveType) &&
                                          isGiveawayObjectiveAuthorized(associatedPU.productionUnit.id)
                                      "
                                    >
                                      <v-col cols="6">
                                        <wx-text-field
                                          v-model.number="objective.objectiveValue"
                                          type="number"
                                          min="0"
                                          max="99999"
                                          :label="$t('product.wizard.update.targetGiveawayPercentage')"
                                          suffix="%"
                                          :rules="[() => validateGiveawayObjectiveValue(objective.objectiveValue)]"
                                        />
                                      </v-col>
                                    </v-row>
                                  </li>
                                </ul>
                              </v-col>
                            </v-row>
                          </fieldset>
                        </section>

                        <!-- - - - - - - - -     Data Source Conversions     - - - - - - - - - - -  -->
                        <section v-if="associatedPU.dataSources && associatedPU.dataSources.length > 0">
                          <span class="pa-0 mt-0">
                            {{ $t("product.details.conversionFactor") }}
                            <wx-contextualized-help
                              :tooltip-text="$t('product.wizard.conversionFactorSubSectionTooltip')"
                            />
                          </span>
                          <v-row
                            v-for="(dataSource, dsIndex) in associatedPU.dataSources"
                            :key="`pu-${index}-ds${dsIndex}`"
                            class="mt-3"
                          >
                            <v-col class="d-flex pt-0 pb-0">
                              <wx-autocomplete
                                v-model="dataSource.dataSourceId"
                                :items="getAvailableDataSources(associatedPU.productionUnit.id)"
                                :label="$t('devices.status.dataSourceId')"
                                :title="getConversionFactorForDataSourceTitle(dataSource)"
                                append-icon=""
                                class="mr-2 dataSourceName"
                                disabled
                              />
                              <wx-text-field
                                v-model.number="dataSource.conversionFactorCount"
                                :label="$t('product.details.conversionFactorCapturedQuantity') + ' *'"
                                :suffix="$t('product.details.conversionFactorCount')"
                                :rules="[() => validateConversionFactorCount(dataSource.conversionFactorCount)]"
                                type="number"
                                min="0"
                                max="9999999999"
                                class="mr-5 conversionFactor"
                              />
                              <wx-text-field
                                v-model.number="dataSource.conversionFactorQuantity"
                                :label="$t('product.details.conversionFactorRealQuantity') + ' *'"
                                :suffix="getDataSourceUnitLabel(dataSource.unit)"
                                :rules="[
                                  () => validateConversionFactorRealQuantity(dataSource.conversionFactorQuantity),
                                ]"
                                type="number"
                                min="0"
                                max="9999999999"
                                class="equal-before conversionFactor mr-2"
                              />
                            </v-col>
                          </v-row>
                        </section>
                      </li>
                    </ul>
                  </v-form>
                  <!-- - - - - - - - - - - -       End of Production Units       - - - - - - - - - - - -->
                  <footer class="bottom-add-row d-flex flex-row-reverse">
                    <wx-btn-standard
                      text
                      @click="addNewLine"
                      :title="$t('product.wizard.creation.buttonAddNewLineHoverTitle')"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      {{ $t("product.wizard.creation.buttonAddNewLine") }}
                    </wx-btn-standard>
                  </footer>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <!-- - - - - - - - -       Units of Conversion Section       - - - - - - - - - - - -->
        <v-card class="product-section-card">
          <v-card-text>
            <v-expansion-panels class="expansion-panel-style" flat v-model="unitOfConversionAccordionOpen">
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("product.wizard.unitsConversionTitle") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="unitConversionForm" lazy-validation v-model="isUnitConversionSectionValid">
                    <!----------------- Conversion Factor ----------------->
                    <section class="product-target-row">
                      <fieldset class="inline-fields" v-if="unitConversions.length !== 0">
                        <span>{{ $t("product.wizard.countedUnitsConversionTitle") }}</span>
                        <v-list>
                          <!-- Dynamically create the conversion chain rows -->
                          <v-list-item
                            v-for="(conversion, unitChainConversionIndex) in unitConversions"
                            :key="'conversion-chain' + unitChainConversionIndex"
                          >
                            <v-row align="center">
                              <!-- From factor -->
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <wx-text-field
                                  v-model.number="conversion.from_factor"
                                  :label="$t('product.wizard.quantity')"
                                  :rules="[() => validateFactorConversion(conversion.from_factor)]"
                                  min="0"
                                  max="99999"
                                  type="number"
                                >
                                </wx-text-field>
                              </v-col>
                              <!-- From unit -->
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <wx-autocomplete
                                  v-model="conversion.from_unit"
                                  :items="availableUnitNames(unitChainConversionIndex)"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('productionUnit.details.convertedUnitName')"
                                  :rules="[() => validateUnitConversion(conversion.from_unit, conversion.to_unit)]"
                                  :disabled="unitChainConversionIndex !== 0 || unitConversions.length > 1"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col cols="12" md="1" lg="1" xl="1" align="center">
                                <p><b>=</b></p>
                              </v-col>
                              <!-- To factor -->
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <wx-text-field
                                  v-model.number="conversion.to_factor"
                                  :label="$t('product.wizard.quantity')"
                                  :rules="[() => validateFactorConversion(conversion.to_factor)]"
                                  min="0"
                                  max="99999"
                                  type="number"
                                >
                                </wx-text-field>
                              </v-col>
                              <!-- To Unit -->
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <wx-autocomplete
                                  v-model="conversion.to_unit"
                                  :items="availableUnitNames()"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('productionUnit.details.convertedUnitName')"
                                  :rules="[() => validateUnitConversion(conversion.to_unit, conversion.from_unit)]"
                                  :disabled="unitChainConversionIndex !== unitConversions.length - 1"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col v-if="$vuetify.breakpoint.smAndDown">
                                <hr />
                              </v-col>
                              <v-col
                                class="d-flex field-col"
                                :class="{
                                  'justify-center': $vuetify.breakpoint.smAndDown,
                                  'justify-end': $vuetify.breakpoint.mdAndUp,
                                }"
                                cols="12"
                                md="1"
                                lg="1"
                                xl="1"
                              >
                                <wx-btn-standard
                                  color="transparent"
                                  class="mb-5 mr-0 pr-0 pl-0 ml-0"
                                  v-if="unitChainConversionIndex === unitConversions.length - 1"
                                  @click="removeUnitConversion"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </wx-btn-standard>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list>

                        <!-- Alert for circular unit chains -->
                        <v-row justify="center" v-if="showCircularDependencyError">
                          <v-col cols="auto">
                            <v-alert bordered type="error" text outlined>
                              {{ $t("product.errors.circularUnitChainError") }}
                            </v-alert>
                          </v-col>
                        </v-row>

                        <!-- Button to create next unit conversion chain -->
                        <v-row justify="end">
                          <v-col cols="auto">
                            <wx-btn-standard
                              color="secondary"
                              class="cancel-btn"
                              @click="createNextUnitConversion"
                              :disabled="!validateLastUnitChainConversion()"
                              id="pendo-unit-chain-conversion-btn-1"
                            >
                              {{ $t("product.wizard.conversionBtn") }}
                            </wx-btn-standard>
                          </v-col>
                        </v-row>
                      </fieldset>
                      <!-- - - - - - - - - - - - Button to create first unit conversion chain - - - - - - - - - - - - - - -->
                      <v-row v-else justify="end">
                        <v-col>
                          {{ $t("product.wizard.countedUnitsConversionTitle") }}
                        </v-col>
                        <v-col cols="auto">
                          <wx-btn-standard
                            color="secondary"
                            class="cancel-btn"
                            @click="createEmptyUnitConversion"
                            id="pendo-unit-chain-conversion-btn-2"
                          >
                            {{ $t("product.wizard.conversionBtn") }}
                          </wx-btn-standard>
                        </v-col>
                      </v-row>
                    </section>
                    <!----------------- Weight / Length / Volume Conversion ----------------->
                    <section class="product-target-row">
                      <fieldset class="inline-fields" v-if="weightLengthVolumeConversionExists">
                        <span>{{ $t("product.wizard.wlvConversionTitle") }}</span>
                        <v-row justify="space-between">
                          <v-col cols="auto">
                            <span class="font-italic text--disabled">{{
                              $t("product.wizard.wlvUnitConversionWarning")
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row v-if="missingAssociatedPuUnitsInChain.length > 0" justify="space-between">
                          <v-col cols="auto">
                            <span class="font-italic text--disabled">{{
                              $t("product.wizard.wlvUnitConversionDisabledWarning", {
                                missingUnits: formattedMissingUnits,
                              })
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-list>
                          <v-list-item>
                            <v-row align="center">
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <wx-text-field
                                  value="1"
                                  disabled
                                  :label="$t('product.wizard.quantity')"
                                ></wx-text-field>
                              </v-col>
                              <!-- Target base value for weight / length / volume -->
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <wx-autocomplete
                                  v-model="targetValueBaseUnit"
                                  :items="availableUnitNamesForWLVTargets"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('productionUnit.details.convertedUnitName')"
                                  :disabled="missingAssociatedPuUnitsInChain.length > 0"
                                  clearable
                                  @input="onWLVConversionTargetChange"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col cols="12" md="1" lg="1" xl="1" align="center">
                                <p><b>=</b></p>
                              </v-col>
                              <v-col class="field-col" cols="6" md="3" lg="3" xl="3">
                                <!-- - - - - - - - - - - - -     Target Value     - - - - - - - - - - - - - - - -->
                                <wx-text-field
                                  ref="targetValue"
                                  v-model.number="targetValue"
                                  min="0"
                                  max="99999"
                                  :label="$t('product.details.targetValue')"
                                  type="number"
                                  :rules="[() => validateTargetValue()]"
                                  @change="revalidateTargetFields"
                                  @input="onWLVConversionTargetChange"
                                />
                              </v-col>
                              <v-col class="field-col" cols="6" md="2" lg="2" xl="2">
                                <!-- - - - - - - - - - - -       Target Unit       - - - - - - - - - - - - - - -->
                                <wx-autocomplete
                                  v-model="targetUnit"
                                  :items="availableWeightLengthVolumeTargetUnits"
                                  :label="$t('product.details.targetUnit')"
                                  :rules="[() => validateTargetUnit()]"
                                  @change="onTargetUnitChange"
                                  item-text="name"
                                  item-value="id"
                                  validate-on-blur
                                  @input="onWLVConversionTargetChange"
                                >
                                </wx-autocomplete>
                              </v-col>
                              <v-col
                                class="d-flex field-col"
                                :class="{
                                  'justify-center': $vuetify.breakpoint.smAndDown,
                                  'justify-end': $vuetify.breakpoint.mdAndUp,
                                }"
                                cols="12"
                                md="1"
                                lg="1"
                                xl="1"
                              >
                                <wx-btn-standard
                                  color="transparent"
                                  class="mb-5 mr-0 pr-0 pl-0 ml-0"
                                  @click="removeWeightLengthVolumeConversion"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </wx-btn-standard>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list>
                      </fieldset>
                      <!-- - - - - - - - - - - - Weight / Length / Volume Converion Row with no data - - - - - - - - - - - - - - -->
                      <v-row v-else justify="end">
                        <v-col>
                          {{ $t("product.wizard.wlvConversionTitle") }}
                        </v-col>
                        <v-col cols="auto">
                          <wx-btn-standard
                            color="secondary"
                            class="cancel-btn"
                            @click="showWeightLengthVolumeConversion = true"
                            id="pendo-wlv-conversion-btn"
                          >
                            {{ $t("product.wizard.conversionBtn") }}
                          </wx-btn-standard>
                        </v-col>
                      </v-row>
                    </section>
                  </v-form>

                  <!-- Alert for broken target base value weight chains -->
                  <v-row justify="center" v-if="isWeightLengthVolumeTargetBroken">
                    <v-col cols="auto">
                      <v-alert bordered type="error" text outlined>
                        {{ brokenWeightLengthVolumeTargetErrorMessage }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <!-- - - - - - - - -       Giveaway Tolerance Section       - - - - - - - - - - - -->
        <v-card v-show="isProductRelatedToSF4ProductionUnits()" class="product-section-card">
          <v-card-text>
            <v-expansion-panels v-model="giveawayAccordionOpen" class="expansion-panel-style" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="py-1 text-h6">
                  {{ $t("product.wizard.giveaway") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-form ref="giveawayForm" lazy-validation v-model="isGiveawaySectionValid">
                    <section class="product-target-row">
                      <fieldset class="inline-fields">
                        <v-row class="mx-0">
                          <span class="mr-2">{{ $t("product.wizard.targetSubSectionTitle") }}</span>
                          <wx-contextualized-help :tooltip-text="$t('product.wizard.targetSubSectionTooltip')" />
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="font-italic text--disabled">{{ giveawayTargetWarning }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="field-col">
                            <!-- - - - - - - - - - - - -     Target Value     - - - - - - - - - - - - - - - -->
                            <wx-text-field
                              ref="targetValue"
                              v-model.number="targetValue"
                              :label="$t('product.details.targetValue')"
                              :suffix="getTargetUnitLabel()"
                              disabled
                            />
                          </v-col>
                          <v-col class="field-col">
                            <!-- - - - - - - - - - - - -     Lower Tolerance Value     - - - - - - - - - - - - - - - -->
                            <wx-text-field
                              ref="lowerTolerance"
                              v-model.number="targetLowerTolerance"
                              min="0"
                              max="99999"
                              :label="$t('product.details.targetLowerTolerance')"
                              :suffix="getTargetUnitLabel()"
                              type="number"
                              :rules="[() => validateTargetLowerTolerance()]"
                              :disabled="!targetValue || !targetUnit"
                              @blur="revalidateTargetFields"
                            />
                          </v-col>
                          <v-col class="field-col">
                            <!-- - - - - - - - - - - - -     Upper Tolerance Value     - - - - - - - - - - - - - - - -->
                            <wx-text-field
                              ref="upperTolerance"
                              v-model.number="targetUpperTolerance"
                              min="0"
                              max="99999"
                              :label="$t('product.details.targetUpperTolerance')"
                              :suffix="getTargetUnitLabel()"
                              type="number"
                              :rules="[() => validateTargetUpperTolerance()]"
                              :disabled="!targetValue || !targetUnit"
                              @blur="revalidateTargetFields"
                            />
                          </v-col>
                        </v-row>
                      </fieldset>
                    </section>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>
      <form-footer-actions class="pb-0 mb-0" :click-submit="submit" cancel-to="toProducts" />
      <v-alert v-if="!isFormValid" type="error" class="mt-3 pb-1" icon="$alertIcon" outlined text>
        <p>{{ $t("common.formError") }}</p>
      </v-alert>
      <!-- </v-form> -->
    </wx-form-container>
  </fragment>
</template>

<script>
import WxFormContainer from "@/components/ui/WxFormContainer";
import FormFooterActions from "@/components/ui/FormFooterActions";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxTextField from "@/components/ui/WxTextField";
import WxSelect from "@/components/ui/WxSelect";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import ProductService from "@/components/product/ProductService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import Validations from "@/components/validations";
import { mapActions, mapGetters } from "vuex";
import PackageFeatures from "@/components/PackageFeatures";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp";
import ProductObjectives from "@/components/product/ProductObjectives";
import { compareName } from "@/components/SortUtils";
import ConversionUnitChainValidations from "@/components/product/ConversionUnitChainValidations";
import { getUnitType } from "@/components/user/UserPreferencesService";
import { getUnitName } from "@/components/dashboard/tileselectormenu/TileHelper";
import * as UnitUtils from "@/UnitUtils";
import { dash } from "@/helpers";

export default {
  name: "ProductWizard",
  components: {
    WxFormContainer,
    FormFooterActions,
    WxBtnStandard,
    WxSelect,
    WxTextField,
    WxAutocomplete,
    WxContextualizedHelp,
  },
  props: {
    initialProduct: {
      type: Object,
      default: null,
    },
    productionUnits: {
      type: Array,
      required: true,
    },
    factoryDataSources: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panels: [],
      isDetailsSectionValid: true,
      isProductionUnitSectionValid: true,
      isUnitConversionSectionValid: true,
      isGiveawaySectionValid: true,
      detailsAccordionOpen: 0,
      productionUnitAccordionOpen: null,
      unitOfConversionAccordionOpen: null,
      giveawayAccordionOpen: null,
      isSubmitButtonDisabled: true,
      sku: null,
      name: null,
      category: null,
      isCreation: true,
      targetLowerTolerance: null,
      targetUpperTolerance: null,
      associatedProductionUnits: [
        {
          associationId: null,
          productionUnit: {},
          dataSource: null,
          dataSources: [],
          conversionFactorCount: 1,
          conversionFactorQuantity: 1,
          objectives: [
            {
              objectiveType: ProductObjectives.rateObjectiveName,
              objectiveValue: 1,
              objectiveValueUnit: "unit",
            },
            {
              objectiveType: ProductObjectives.giveawayObjectiveName,
              objectiveValue: 1,
            },
          ],
        },
      ],
      skuError: false,
      skuErrorMessages: [],
      skuMaxLength: Validations.productSkuMaxLength,
      productNameMaxLength: Validations.productNameMaxLength,
      productCategoryMaxLength: Validations.productCategoryMaxLength,
      conversionFactorMaxLength: Validations.productConversionFactorMaxLength,
      productObjectiveMaxLength: Validations.productObjectiveMaxLength,

      unitConversions: [],

      targetValue: null, // target weight / length / volume value
      targetUnit: null, // target weight / length / volume unit, ex. g, kg, mL etc
      targetValueBaseUnit: null, // cans, bags, etc

      showWeightLengthVolumeConversion: false,

      showCircularDependencyError: false,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.isDetailsSectionValid &&
        this.isProductionUnitSectionValid &&
        this.isUnitConversionSectionValid &&
        this.isGiveawaySectionValid
      );
    },
    ...mapGetters("navigation", ["activeFactoryId"]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    currentProductionUnits() {
      let puList = [...this.productionUnits];
      puList.sort(compareName);
      return puList;
    },
    availableWeightLengthVolumeTargetUnits() {
      let allOptions = [{ id: "-", name: this.$t("common.none") }];
      let productAvailableTargetUnits = Validations.getProductAvailableTargetUnits();
      allOptions.push(...productAvailableTargetUnits);
      return allOptions;
    },
    productTitle() {
      let description = this.name?.length > 100 ? this.name?.slice(0, 97) + "..." : this.name;
      return this.name ? description : this.sku;
    },
    missingAssociatedPuUnitsInChain() {
      const unitChain = ConversionUnitChainValidations.getUnitChain(this.unitConversions);
      return this.associatedProductionUnits
        .map((associatedPu) => associatedPu.productionUnit.converted_unit_name)
        .filter((convertedUnitName) => !!convertedUnitName && !unitChain.includes(convertedUnitName));
    },
    formattedMissingUnits() {
      return this.missingAssociatedPuUnitsInChain.map((unit) => getUnitName(unit).name).join(", ");
    },
    availableUnitNamesForWLVTargets() {
      const allOptions = [];
      const unitChain = ConversionUnitChainValidations.getUnitChain(this.unitConversions);
      let availableUnits = this.availableUnitNames().map((unit) => {
        return {
          ...unit,
          disabled: this.missingAssociatedPuUnitsInChain.length > 0 ? true : !unitChain.includes(unit.id),
        };
      });
      allOptions.push(...availableUnits);
      return allOptions;
    },
    weightLengthVolumeConversionExists() {
      return (this.targetValue && this.targetUnit) || this.showWeightLengthVolumeConversion;
    },
    giveawayTargetWarning() {
      if (!this.targetUnit) return null;
      switch (getUnitType(this.targetUnit.toLowerCase())) {
        case "weight_unit":
          return this.$t("product.wizard.giveawayTargetWarning.weight");
        case "volume_unit":
          return this.$t("product.wizard.giveawayTargetWarning.volume");
        case "length_unit":
          return this.$t("product.wizard.giveawayTargetWarning.length");
      }
      return null;
    },
    brokenTargets() {
      const pus = this.associatedProductionUnits.map((associatedPU) => {
        return {
          name: associatedPU?.productionUnit?.name,
          convertedUnitName: associatedPU?.productionUnit?.converted_unit_name,
        };
      });

      const baseUnit = !this.targetValueBaseUnit || this.targetValueBaseUnit === "-" ? null : this.targetValueBaseUnit;
      return ConversionUnitChainValidations.detectBrokenTargets(pus, baseUnit, this.unitConversions);
    },
    isWeightLengthVolumeTargetBroken() {
      return this.brokenTargets.isBroken;
    },
    brokenWeightLengthVolumeTargetErrorMessage() {
      if (!this.isWeightLengthVolumeTargetBroken) return null;
      const missingUnitLabelsTransformed = this.brokenTargets.missingUnits
        .map((unit) => {
          const name = Validations.getProductionUnitAvailableUnits().find((puUnit) => puUnit.id === unit)?.name;
          if (!name) return null;
          return `"${name}"`;
        })
        .filter((name) => !!name)
        .join(",");
      const unitLabelTransformed = Validations.getProductionUnitAvailableUnits().find(
        (puUnit) => puUnit.id === this.brokenTargets.baseUnit,
      )?.name;
      return this.$t("product.errors.targetProductConversionError", {
        unitName: unitLabelTransformed,
        targetUnit: this.targetUnit,
        missingUnits: missingUnitLabelsTransformed,
      });
    },
  },
  watch: {
    initialProduct() {
      if (this.initialProduct) {
        this.isCreation = false;
        this.sku = this.initialProduct.sku;
        this.skuMaxLength = undefined;
        this.category = this.initialProduct.category;
        this.name = this.initialProduct.description;

        // Production Units
        this.setAssociatedProductionUnits(this.initialProduct.associated_production_units);

        // Conversion chain
        this.setUnitConversionChain(this.initialProduct.unit_conversions);

        // Weight / Length / Volume target
        this.targetValue = this.initialProduct.target?.value;
        this.targetUnit = this.initialProduct.target?.unit;
        this.targetValueBaseUnit = this.initialProduct.target?.base_unit;

        // Giveaway Tolerance
        this.targetLowerTolerance = this.initialProduct.target?.tolerance?.lower_value;
        this.targetUpperTolerance = this.initialProduct.target?.tolerance?.upper_value;
      }
    },
    isWeightLengthVolumeTargetBroken(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.targetValueBaseUnit = null;
      }
    },
    unitConversions() {
      this.$refs.productionUnitsForm.resetValidation();
      this.$refs.productionUnitsForm.validate();
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    onWLVConversionTargetChange() {
      this.giveawayAccordionOpen = 0; // open the giveaway section if there is one.
    },
    transformObjectiveUnit(unit) {
      const transformedUnit = { ...unit };
      transformedUnit.id = this.getObjectiveUnit(unit.id);
      return transformedUnit;
    },
    availableRateObjectiveUnits(isDisabled) {
      const perHourUnitText = this.$t("common.perHour");

      let countUnits = [];
      if (isDisabled) {
        countUnits = Validations.getProductionUnitAvailableUnits().map(this.transformObjectiveUnit);
      } else {
        countUnits = Validations.getProductionUnitAvailableUnits()
          .filter((u) => u.isCount)
          .map(this.transformObjectiveUnit);
      }
      let availableUnits = [...countUnits];
      if (this.targetUnit) {
        let isWeightTarget = Validations.getWeightAvailableTargetUnits().find((u) => u.id === this.targetUnit);
        if (isWeightTarget) {
          availableUnits.push(...Validations.getWeightUnits().map(this.transformObjectiveUnit));
        }
        let isLengthTarget = Validations.getLengthAvailableTargetUnits().find((u) => u.id === this.targetUnit);
        if (isLengthTarget) {
          availableUnits.push(...Validations.getLengthUnits().map(this.transformObjectiveUnit));
        }
        let isVolumeTarget = Validations.getVolumeAvailableTargetUnits().find((u) => u.id === this.targetUnit);
        if (isVolumeTarget) {
          availableUnits.push(...Validations.getVolumeUnits().map(this.transformObjectiveUnit));
        }
      }
      return availableUnits.map((unit) => ({
        id: unit.id,
        name: `${unit.name}${perHourUnitText}`,
      }));
    },
    availableUnitNames(chainIndex) {
      if (chainIndex === null || chainIndex === undefined) {
        return Validations.getProductionUnitAvailableUnits().filter((unit) => unit.isUnitChainConversion);
      }
      if (chainIndex === 0) {
        const availableUnits = this.associatedProductionUnits
          .map((associatedPu) => associatedPu.productionUnit.converted_unit_name)
          .filter((convertedUnitName) => !!convertedUnitName);
        return Validations.getProductionUnitAvailableUnits().filter((unit) => {
          return unit.isUnitChainConversion && availableUnits.indexOf(unit.id) >= 0;
        });
      }
      return Validations.getProductionUnitAvailableUnits().filter((unit) => unit.isUnitChainConversion);
    },
    getRouteBack() {
      return RouteService.toProducts();
    },
    isProductionUnitSelected(associatedPU) {
      return associatedPU?.productionUnit?.id;
    },
    getPackages(associatedPU) {
      let puPackages = associatedPU?.productionUnit?.packages?.filter((p) => String(p))?.sort();
      return puPackages?.at(-1) ?? null;
    },
    getDataSources(productionUnitId) {
      return this.factoryDataSources.filter((ds) => this.isForPU(ds, productionUnitId));
    },
    getAvailableDataSources(productionUnitId) {
      return this.getDataSources(productionUnitId).map((ds) => ({
        text: `${ds.description} (${ds.role.toUpperCase()})`,
        value: ds.id,
      }));
    },
    isForPU(ds, productionUnitId) {
      return ds.production_unit_ids.includes(productionUnitId);
    },
    getConversionFactorForDataSourceTitle(ds) {
      return `ID: ${ds.dataSourceId} / Device ${ds.deviceId}`;
    },
    getDataSourceUnitLabel(unit) {
      if (unit) {
        let unitObject = Validations.getProductionUnitAvailableUnits().find((u) => u.id === unit.toLowerCase());
        if (unitObject) {
          return unitObject.name;
        } else {
          return unit;
        }
      }
      return dash;
    },
    getTargetUnitLabel() {
      if (this.targetUnit) {
        return UnitUtils.getUnitLabel(this.targetUnit.toLowerCase());
      }
      return dash;
    },
    validateSku() {
      // Sku edition is disabled this.isCreation
      if (this.isCreation) {
        if (!this.sku) {
          return this.$t("product.errors.noSku");
        }
        if (this.sku.trim().length > this.skuMaxLength) {
          return this.$t("product.errors.skuTooLong", {
            maxLength: this.skuMaxLength,
          });
        }
      }
      this.skuError = false;
      this.skuErrorMessages = [];
      return true;
    },
    validateName() {
      if (!!this.name && this.name.trim().length > this.productNameMaxLength) {
        return this.$t("product.errors.nameTooLong", {
          maxLength: this.productNameMaxLength,
        });
      }
      return true;
    },
    validateCategory() {
      if (!!this.category && this.category.trim().length > this.productCategoryMaxLength) {
        return this.$t("product.errors.categoryTooLong", {
          maxLength: this.productCategoryMaxLength,
        });
      }
      return true;
    },
    isSelectedTargetUnitSetToNone() {
      return this.targetUnit === "-";
    },
    onTargetUnitChange() {
      if (this.isSelectedTargetUnitSetToNone()) {
        this.targetValue = null;
        this.targetLowerTolerance = null;
        this.targetUpperTolerance = null;
      }
      this.revalidateTargetFields();
    },
    revalidateTargetFields() {
      this.$refs.targetValue.validate(true);
      this.$refs.lowerTolerance?.validate(true);
      this.$refs.upperTolerance?.validate(true);
    },
    validateTargetUnit() {
      if (this.isSelectedTargetUnitSetToNone()) return true;

      if (this.targetValue && !this.targetUnit) {
        return this.$t("product.errors.noTargetUnit");
      }

      if (this.targetUnit && !Validations.isProductTargetUnitValid(this.targetUnit)) {
        const supportedValues = this.availableWeightLengthVolumeTargetUnits.map((unit) => unit.name).join();
        return this.$t("product.errors.invalidTargetUnit", { supportedValues: supportedValues });
      }
      return true;
    },
    validateTargetValue() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetValue != null && this.targetValue < 0) {
          return this.$t("product.errors.unsupportedTargetValueForUnitNone");
        }
        return true;
      }

      if (this.targetUnit && this.targetValue == null) {
        return this.$t("product.errors.noTargetValue");
      }

      if (this.targetValue != null && this.targetValue === 0) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetValue && !Validations.isProductTargetValueValid(this.targetValue)) {
        return this.$t("product.errors.invalidTargetValue");
      }
      return true;
    },
    validateTargetLowerTolerance() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetLowerTolerance != null && this.targetLowerTolerance > 0) {
          return this.$t("product.errors.unsupportedTargetLowerToleranceForUnitNone");
        }
        return true;
      }

      if (this.targetUpperTolerance && !this.targetLowerTolerance) {
        return this.$t("product.errors.noTargetLowerToleranceValue");
      }

      if (this.targetLowerTolerance && !Validations.isProductTargetValueValid(this.targetLowerTolerance)) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetLowerTolerance > this.targetValue) {
        return this.$t("product.errors.invalidTargetLowerToleranceValue");
      }
      return true;
    },
    validateTargetUpperTolerance() {
      if (this.isSelectedTargetUnitSetToNone()) {
        if (this.targetUpperTolerance != null && this.targetUpperTolerance > 0) {
          return this.$t("product.errors.unsupportedTargetUpperToleranceForUnitNone");
        }
        return true;
      }

      if (this.targetLowerTolerance && !this.targetUpperTolerance) {
        return this.$t("product.errors.noTargetUpperToleranceValue");
      }

      if (this.targetUpperTolerance && !Validations.isProductTargetValueValid(this.targetUpperTolerance)) {
        return this.$t("product.errors.invalidTargetValue");
      }

      if (this.targetUpperTolerance < this.targetValue && this.targetUpperTolerance) {
        return this.$t("product.errors.invalidTargetUpperToleranceValue");
      }
      return true;
    },
    validateConversionFactorCount(conversionFactorCount) {
      if (
        isNaN(conversionFactorCount) ||
        conversionFactorCount <= 0 ||
        conversionFactorCount > 9999999999 ||
        !Validations.isProductConversionFactorValid(conversionFactorCount)
      ) {
        return this.$t("product.errors.invalidConversionFactorCapturedQuantity");
      }
      return true;
    },
    validateConversionFactorRealQuantity(conversionFactorQuantity) {
      if (
        isNaN(conversionFactorQuantity) ||
        conversionFactorQuantity <= 0 ||
        conversionFactorQuantity > 9999999999 ||
        !Validations.isProductConversionFactorValid(conversionFactorQuantity)
      ) {
        return this.$t("product.errors.invalidConversionFactorRealQuantity");
      }
      return true;
    },
    createConversionFactors(associatedProductionUnit) {
      return associatedProductionUnit.dataSources.map((ds) => ({
        device_id: ds.deviceId,
        data_source_id: ds.dataSourceId,
        conversion_factor: ds.conversionFactorCount + ":" + ds.conversionFactorQuantity,
      }));
    },
    isRateObjectiveUnitDisabled(associatedPU) {
      let puConvertedUnitName = associatedPU.productionUnit.converted_unit_name;
      let nonCountUnits = Validations.getProductionUnitAvailableUnits()
        .filter((u) => !u.isCount)
        .map((u) => u.id);
      return nonCountUnits.includes(puConvertedUnitName);
    },
    validateRateObjectiveValue(objectiveValue) {
      let parsedObjectiveValue = parseFloat(objectiveValue);
      if (
        isNaN(parsedObjectiveValue) ||
        parsedObjectiveValue <= 0 ||
        !Validations.isProductRateObjectiveValueValid(objectiveValue)
      ) {
        return this.$t("product.errors.invalidRateObjective");
      }
      return true;
    },
    validateRateObjectiveUnit(objectiveValueUnit, associatedPU) {
      let puConvertedUnitName = associatedPU.productionUnit.converted_unit_name;
      if (objectiveValueUnit === this.getObjectiveUnit(puConvertedUnitName)) {
        // Same unit as PU converted unit. No problem.
        return true;
      }
      // The Target Rate unit is not the same as the PU unit.
      let nonCountUnits = Validations.getProductionUnitAvailableUnits()
        .filter((u) => !u.isCount)
        .map((u) => this.getObjectiveUnit(u.id));
      let isObjectiveInNonCountUnit = nonCountUnits.includes(objectiveValueUnit);
      let isPUInNonCountUnit = nonCountUnits.includes(this.getObjectiveUnit(puConvertedUnitName));

      if (!isPUInNonCountUnit && isObjectiveInNonCountUnit) {
        // PU is in count (unit,case,pack,...) but the Target Rate is not (kg,m,L,...)
        // The dropdown menu allows only valid unit
        return true;
      }

      // Both Target Rate and PU units are of type `count` but are not the same unit.
      // To be valid, the Target Rate unit must be Unit Chain
      if (this.unitConversions.length === 0) {
        return this.$t("product.errors.targetRateUnitNotReachable", { objectiveUnit: objectiveValueUnit });
      } else {
        let found = this.unitConversions.find(
          (uc) => uc.to_unit === objectiveValueUnit || uc.from_unit === objectiveValueUnit,
        );
        if (!found) {
          return this.$t("product.errors.targetRateUnitNotReachable", { objectiveUnit: objectiveValueUnit });
        }
      }
      return true;
    },
    validateGiveawayObjectiveValue(objectiveValue) {
      if (
        isNaN(objectiveValue) ||
        objectiveValue <= 0 ||
        objectiveValue > 100 ||
        !Validations.isProductGiveawayObjectiveValueValid(objectiveValue)
      ) {
        return this.$t("product.errors.invalidGiveawayObjective");
      }

      return true;
    },
    // validate the associatedProductionUnit
    submit() {
      if (this.isCreation) {
        this.create();
      } else {
        this.update();
      }
    },
    isValid() {
      let detailsForm = this.$refs.detailsForm.validate();
      let productionUnitsForm = this.$refs.productionUnitsForm.validate();
      let unitConversionForm = this.$refs.unitConversionForm.validate();
      let giveawayForm = this.$refs.giveawayForm?.validate();
      let isValidForms = detailsForm && productionUnitsForm && unitConversionForm && giveawayForm;

      // Need to check that forms evaluate to false so that null and undefined do not pass the check
      if (detailsForm === false) {
        this.panels.push(0);
      }
      if (productionUnitsForm === false) {
        this.panels.push(1);
      }
      if (unitConversionForm === false || this.showCircularDependencyError || this.isWeightLengthVolumeTargetBroken) {
        this.panels.push(2);
      }
      if (giveawayForm === false) {
        this.panels.push(3);
      }
      return isValidForms && !this.showCircularDependencyError && !this.isWeightLengthVolumeTargetBroken;
    },
    create() {
      const isValid = this.isValid();
      if (isValid) {
        const associatedProductionUnits = this.createAssociatedProductionUnits();
        const target = this.createWeightLengthVolumeProductTarget();
        const unitChainConversions = this.createUnitChainConversion();
        const newProduct = {
          sku: this.sku,
          category: this.category,
          description: this.name,
          target: target,
          unit_conversions: unitChainConversions,
          associated_production_units: associatedProductionUnits,
        };
        ProductService.createProduct(this.activeFactoryId, newProduct)
          .then((response) => this.handleProductCreationResponse(response, newProduct))
          .catch((error) => this.handleProductCreationError(error.response));
      } else {
        this.focusOnError();
      }
    },
    update() {
      const isValid = this.isValid();
      if (isValid) {
        const associatedProductionUnits = this.createAssociatedProductionUnits();
        const target = this.createWeightLengthVolumeProductTarget();
        const unitChainConversions = this.createUnitChainConversion();
        const updatedProduct = {
          id: this.initialProduct.id,
          category: this.category,
          description: this.name,
          target: target,
          unit_conversions: unitChainConversions,
          associated_production_units: associatedProductionUnits,
        };
        ProductService.updateProduct(updatedProduct)
          .then((response) => this.handleProductUpdateResponse(response))
          .catch((error) => this.handleProductUpdateError(error.response));
      } else {
        this.focusOnError();
      }
    },
    createUnitChainConversion() {
      return {
        units: this.unitConversions.map((unitConversion) => {
          return {
            from_unit: unitConversion.from_unit,
            conversion_factor: unitConversion.from_factor + ":" + unitConversion.to_factor,
            to_unit: unitConversion.to_unit,
          };
        }),
      };
    },
    createWeightLengthVolumeProductTarget() {
      let target = null;
      if (!this.isSelectedTargetUnitSetToNone()) {
        let targetTolerance = null;
        if (
          this.targetLowerTolerance != null &&
          this.targetLowerTolerance > 0 &&
          this.targetUpperTolerance != null &&
          this.targetUpperTolerance > 0
        ) {
          targetTolerance = {
            lower_value: this.targetLowerTolerance,
            upper_value: this.targetUpperTolerance,
          };
        }
        if (this.targetValue != null && this.targetUnit != null) {
          target = {
            value: this.targetValue,
            unit: this.targetUnit,
            tolerance: targetTolerance,
            base_unit: this.targetValueBaseUnit === "-" ? null : this.targetValueBaseUnit,
          };
        }
      }
      return target;
    },
    createAssociatedProductionUnits() {
      return this.associatedProductionUnits.map((associatedPU) => ({
        association_id: associatedPU.associationId,
        production_unit_id: associatedPU.productionUnit.id,
        conversion_factors: this.createConversionFactors(associatedPU),
        objectives: this.buildProductionUnitObjectives(associatedPU),
      }));
    },
    buildProductionUnitObjectives(associatedProductionUnit) {
      return associatedProductionUnit.objectives
        .filter((objective) => objective.objectiveValue)
        .map((objective) => ({
          objective_type: objective.objectiveType,
          value: objective.objectiveValue,
          unit: objective.objectiveValueUnit,
        }));
    },
    setAssociatedProductionUnits(associatedProductionUnits) {
      this.associatedProductionUnits = associatedProductionUnits.map((associatedPU) => {
        const puAssociatedDataSources = this.getDataSources(associatedPU.production_unit_id);
        let productionUnit = this.getProductionUnitById(associatedPU.production_unit_id);

        /**
         * use puAssociatedDataSources as the source of truth, as the json in the association conversion factors
         * can be outdated.
         */
        const dataSources = [];
        const defaultConversionFactorCount = associatedPU.conversion_factor.split(":", 2)[0];
        const defaultConversionFactorQuantity = associatedPU.conversion_factor.split(":", 2)[1];

        puAssociatedDataSources.forEach((puDS) => {
          let found = associatedPU.conversion_factors.find((cf) => cf.dataSourceId === puDS.id && cf.deviceId === puDS.device_id);
          if (found) {
            // found the data source in the association conversion factors
            dataSources.push({
              deviceId: found.deviceId,
              dataSourceId: found.dataSourceId,
              dataSourceName: found.dataSourceName,
              role: found.role,
              conversionFactorCount: Number(found.conversionFactor.split(":", 2)[0]),
              conversionFactorQuantity: Number(found.conversionFactor.split(":", 2)[1]),
              unit: found.unit ?? productionUnit.converted_unit_name,
            });
          } else {
            // didn't find it, add it with default values
            dataSources.push({
              deviceId: puDS.device_id,
              dataSourceId: puDS.id,
              dataSourceName: puDS.description,
              role: puDS.role,
              conversionFactorCount: defaultConversionFactorCount,
              conversionFactorQuantity: defaultConversionFactorQuantity,
              unit: puDS.unit ?? productionUnit.converted_unit_name,
            });
          }
        });

        return {
          associationId: associatedPU.association_id,
          productionUnit: productionUnit,
          dataSources: dataSources,
          conversionFactorCount: Number(defaultConversionFactorCount),
          conversionFactorQuantity: Number(defaultConversionFactorQuantity),
          objectives: this.getObjectives(associatedPU.objectives, productionUnit.converted_unit_name),
        };
      });
    },
    setUnitConversionChain(productUnitConversions) {
      this.unitConversions =
        productUnitConversions?.units?.map((unitConversion) => {
          const factors = unitConversion.conversion_factor.split(":", 2);
          return {
            from_unit: unitConversion.from_unit,
            from_factor: Number(factors[0]),
            to_factor: Number(factors[1]),
            to_unit: unitConversion.to_unit,
          };
        }) ?? [];
    },
    getProductionUnitById(productionUnitId) {
      return this.productionUnits.find((pu) => pu.id === productionUnitId);
    },
    isRateObjective(objectiveType) {
      return objectiveType === ProductObjectives.rateObjectiveName;
    },
    isGiveawayObjective(objectiveType) {
      return objectiveType === ProductObjectives.giveawayObjectiveName;
    },
    getObjectives(associatedPUObjectives, defaultConvertedUnitName) {
      this.createEmptyRateObjectiveIfNecessary(associatedPUObjectives, defaultConvertedUnitName);
      this.createEmptyGiveawayObjectiveIfNecessary(associatedPUObjectives);
      return associatedPUObjectives.map((objective) => ({
        objectiveType: objective.objective_type,
        objectiveValue: objective.value,
        objectiveValueUnit: objective.objective_type === "units_per_hour" ? objective.unit : null,
      }));
    },
    createEmptyRateObjectiveIfNecessary(associatedPUObjectives, defaultConvertedUnitName) {
      let rateObjective = associatedPUObjectives.find(
        (obj) => obj.objective_type === ProductObjectives.rateObjectiveName,
      );
      if (!rateObjective) {
        associatedPUObjectives.push({
          objective_type: ProductObjectives.rateObjectiveName,
          value: null,
          unit: this.getObjectiveUnit(defaultConvertedUnitName),
        });
      } else {
        // If the `unit` is null, set it with the `defaultConvertedUnitName`
        if (!rateObjective.unit) {
          rateObjective["unit"] = this.getObjectiveUnit(defaultConvertedUnitName);
        }
      }
    },
    createEmptyGiveawayObjectiveIfNecessary(associatedPUObjectives) {
      if (!associatedPUObjectives.some((obj) => obj.objective_type === ProductObjectives.giveawayObjectiveName)) {
        associatedPUObjectives.push({
          objective_type: ProductObjectives.giveawayObjectiveName,
          value: null,
          unit: "%",
        });
      }
    },
    isRateObjectiveAuthorized(productionUnitId) {
      return this.puHasRequiredFeature(productionUnitId, PackageFeatures.performance);
    },
    isGiveawayObjectiveAuthorized(productionUnitId) {
      return this.puHasRequiredFeature(productionUnitId, PackageFeatures.giveaway);
    },
    handleProductCreationResponse(httpResponse, newProduct) {
      if (httpResponse.status === 201) {
        this.showOperationSuccess(this.$t("product.successfullyCreated", { sku: newProduct.sku }));
        this.close();
      }
    },
    handleProductUpdateResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.showOperationSuccess(this.$t("product.successfullyUpdated", { sku: this.sku }));
        this.close();
      }
    },
    handleProductCreationError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      if (httpResponse.status === 409) {
        this.skuErrorMessages = errorMessage;
        this.skuError = true;
      } else {
        this.showOperationError(errorMessage);
      }
    },
    handleProductUpdateError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      this.showOperationError(errorMessage);
    },
    getErrorMessage(code, args, message) {
      switch (code) {
        case "CS_PROD_POST_20001":
          return this.$t("product.errors.duplicateSku");
        case "DSH_PROD_PUT_10002": {
          const regex = /sku '(.*)' is currently selected on Production Unit '(.*)';/gm;
          let matches = regex.exec(message);
          let sku = !!matches && matches.length >= 3 ? matches[1] : "";
          let puName = !!matches && matches.length >= 3 ? matches[2] : "";
          return this.$t("product.errors.productInSkuPreventingAssociationDeletion", { sku: sku, puName: puName });
        }
      }
      if (code === "CS_PROD_POST_20001") return this.$t("product.errors.duplicateSku");
      else return this.$t("common.errors.default", { code: code });
    },
    addNewLine() {
      this.createEmptyAssociatedProductionUnit();
    },
    createEmptyAssociatedProductionUnit() {
      this.associatedProductionUnits.push({
        associationId: null,
        productionUnit: {},
        conversionFactorCount: 1,
        conversionFactorQuantity: 1,
        objectives: [
          {
            objectiveType: ProductObjectives.rateObjectiveName,
            objectiveValue: 1,
          },
          {
            objectiveType: ProductObjectives.giveawayObjectiveName,
            objectiveValue: 1,
          },
        ],
      });
    },
    isProductAssociatedToOneProductionUnit() {
      // The product will be associated at least to one production unit.
      return this.associatedProductionUnits.length === 1;
    },
    deleteLine(index) {
      this.associatedProductionUnits.splice(index, 1);
    },
    onSelectedProductionUnit(selectedProductionUnit, associatedPUIndex) {
      let associatedPU = this.associatedProductionUnits[associatedPUIndex];
      associatedPU.productionUnit = selectedProductionUnit;
      const rateObjective = associatedPU.objectives.find((obj) => obj.objectiveType === "units_per_hour");
      if (rateObjective) {
        rateObjective.objectiveValueUnit = this.getObjectiveUnit(selectedProductionUnit.converted_unit_name);
      }
      // Add a Conversion Factor for each Data Source associated to the PU
      let dataSources = this.factoryDataSources
        .filter((ds) => ds.role === "in" || ds.role === "out")
        .filter((ds) => this.isForPU(ds, selectedProductionUnit.id));

      associatedPU.dataSources = dataSources.map((ds) => ({
        deviceId: ds.device_id,
        dataSourceId: ds.id,
        dataSourceName: ds.description,
        role: ds.role,
        conversionFactorCount: 1,
        conversionFactorQuantity: 1,
        unit: ds.unit ? ds.unit : associatedPU.productionUnit.converted_unit_name,
      }));
    },
    isProductionUnitMissing(selectedProductionUnitId) {
      if (!selectedProductionUnitId) return this.$t("product.errors.missingProductionUnit");
      return true;
    },
    isSelectedProductionUnitDuplicated(selectedProductionUnitId) {
      let productionUnitDuplicatedCount = this.associatedProductionUnits
        .map((associatedPU) => associatedPU?.productionUnit?.id)
        .filter((productionUnitId) => {
          return productionUnitId === selectedProductionUnitId;
        }).length;
      if (productionUnitDuplicatedCount > 1) return this.$t("product.errors.duplicatedProductionUnit");
      return true;
    },
    close() {
      this.panels = [];
      this.sku = null;
      this.name = null;
      this.category = null;
      this.isCreation = true;
      this.targetValue = null;
      this.targetUnit = null;
      this.targetLowerTolerance = null;
      this.targetUpperTolerance = null;
      this.associatedProductionUnits = [];
      this.unitConversions = [];
      this.targetValue = null;
      this.targetUnit = null;
      this.targetValueBaseUnit = null;
      this.showWeightLengthVolumeConversion = false;
      this.showCircularDependencyError = false;
      this.$refs.detailsForm.resetValidation();
      this.$refs.productionUnitsForm.resetValidation();
      this.$refs.unitConversionForm.resetValidation();
      this.$refs.giveawayForm?.resetValidation();
      this.$router.push(this.getRouteBack());
    },
    createEmptyUnitConversion() {
      this.unitConversions.push({
        from_unit: null,
        from_factor: null,
        to_unit: null,
        to_factor: null,
      });
    },
    tryOpenAccordion() {
      //since the tryScrollToErrorLabel goes to the first label, look for the first invalid form and open its accordion
      if (!this.isDetailsSectionValid) {
        this.detailsAccordionOpen = 0;
        return true;
      } else if (!this.isProductionUnitSectionValid) {
        this.productionUnitAccordionOpen = 0;
        return true;
      } else if (!this.isUnitConversionSectionValid) {
        this.unitOfConversionAccordionOpen = 0;
        return true;
      } else if (!this.isGiveawaySectionValid) {
        this.giveawayAccordionOpen = 0;
        return true;
      }
    },
    focusOnError() {
      let timeout = 0;
      if (this.tryOpenAccordion()) {
        timeout = 250;
      }
      setTimeout(() => {
        this.tryScrollToErrorLabel();
      }, timeout);
    },
    /**
     * Tries to scroll and center to the erroneous input field
     */
    tryScrollToErrorLabel() {
      // if there's an error in validation then it's likely the case that the error--text is present
      let elementToScrollTo = document.querySelector('[role="alert"].error--text');
      if (elementToScrollTo) {
        const grandParent = elementToScrollTo.parentNode?.parentNode;
        const siblingSlot = grandParent?.querySelector(".v-input__slot");
        if (siblingSlot) {
          elementToScrollTo = siblingSlot;
        }
        elementToScrollTo.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    createNextUnitConversion() {
      const lastConversion = this.unitConversions[this.unitConversions.length - 1];
      // The destination unit of the last conversion is the source unit of the next conversion
      const fromUnit = lastConversion.to_unit;
      this.unitConversions.push({
        from_unit: fromUnit,
        from_factor: null,
        to_unit: null,
        to_factor: null,
      });
    },
    removeUnitConversion() {
      if (this.unitConversions.length > 0) {
        this.unitConversions.pop();
      }
    },
    removeWeightLengthVolumeConversion() {
      this.targetValue = null;
      this.targetUnit = null;
      this.targetValueBaseUnit = null;
      this.targetLowerTolerance = null;
      this.targetUpperTolerance = null;

      this.showWeightLengthVolumeConversion = false;
      this.$refs.targetValue.resetValidation();
    },
    isProductRelatedToSF4ProductionUnits() {
      return this.associatedProductionUnits.some((pu) => pu?.productionUnit?.packages?.includes("SF4"));
    },
    validateUnitConversion(unitConversionInput, otherUnitConversionInput) {
      if (!unitConversionInput || unitConversionInput.trim().length === 0) {
        return this.$t("product.errors.noUnitConversionError");
      } else if (unitConversionInput === otherUnitConversionInput) {
        return this.$t("product.errors.sameUnitConversionError");
      }
      return true;
    },
    validateFactorConversion(factorConversion) {
      if (!factorConversion || typeof factorConversion !== "number" || factorConversion <= 0) {
        return this.$t("product.errors.invalidFactorConversionError");
      }

      return true;
    },
    validateLastUnitChainConversion() {
      const lastConversion = this.unitConversions[this.unitConversions.length - 1];
      let fromUnitConversionValidation = this.validateUnitConversion(lastConversion.from_unit, lastConversion.to_unit);
      let toUnitConversionValidation = this.validateUnitConversion(lastConversion.to_unit, lastConversion.from_unit);
      let fromFactorConversionValidation = this.validateFactorConversion(lastConversion.from_factor);
      let toFactorConversionValidation = this.validateFactorConversion(lastConversion.to_factor);

      return !(typeof fromUnitConversionValidation === "string" ||
        typeof toUnitConversionValidation === "string" ||
        typeof fromFactorConversionValidation === "string" ||
        typeof toFactorConversionValidation === "string" ||
        this.detectCircularDependencies());
    },
    detectCircularDependencies() {
      const isCircular = ConversionUnitChainValidations.detectCircularUnitChain(this.unitConversions);
      this.showCircularDependencyError = isCircular;
      return isCircular;
    },
    getObjectiveUnit(unit) {
      switch (unit) {
        case "unit":
        case "case":
        case "pack":
        case "bottle":
        case "box":
        case "cycle":
        case "bag":
        case "can":
        case "tray":
        case "panel":
        case "pallet":
          return unit;
        case "kilogram":
          return "kg";
        case "ton":
          return "mt";
        case "pound":
          return "lb";
        case "ounce":
          return "oz";
        case "meter":
          return "m";
        case "feet":
          return "ft";
        case "liter":
          return "l";
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-target-row,
.convert-factor-objective-container {
  .inline-fields {
    width: 100%;
  }
}

.product-target-row {
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.convert-factor-objective-container {
  display: flex;
  padding-bottom: var(--box-padding-admin);

  .objectives-list {
    flex-shrink: 0;
  }
}

.associated-pu-list {
  .pu-item {
    .legend-header {
      display: flex;

      .v-btn {
        margin-top: 0;
        margin-left: auto; // aligned to right
      }
    }
  }
}

// Unknowen containers. Could be obsolete (mDube, may 2022)
.product-tolerance-value {
  width: 45%;
}

.form-style {
  background-color: transparent;
  &.wizard {
    max-width: 850px;
  }
}

.product-section-card {
  background-color: var(--color-element-layer1);
}

.expansion-panel-style {
  .v-expansion-panel-header {
    background-color: var(--color-element-layer1);
  }
  .v-expansion-panel-content {
    background-color: var(--color-element-layer1);
  }
}

.conversion-description {
  color: #71797e;
}
.dataSourceName {
  min-width: 300px;
}
.conversionFactor {
  min-width: 100px;
  max-width: 170px;
}
.production-objective-unit {
  width: 160px;
}
</style>
