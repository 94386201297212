<template>
  <div>
    <slot name="checkbox" />
    <fieldset v-if="isSelected" class="ml-10">
      <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.whatsapp.info") }}</h5>
      <v-phone-input
        ref="whatsAppPhone"
        v-model="inputWhatsappPhone"
        :label="$t('alerts.wizard.deliveryMode.whatsapp.inputLabel')"
        :all-countries="allCountries"
        :error-messages="whatsAppPhoneErrorMessages"
        country-label=""
        validate-on-blur
        @blur="onNewWhatsappPhone"
        @keydown="onWhatsappPhoneKeyDown"
      />
      <v-combobox
        v-model="recipientWhatsapp"
        :rules="recipientWhatsappRules"
        :label="$t('alerts.wizard.deliveryMode.whatsapp.listLabel')"
        hide-selected
        multiple
        small-chips
        filled
        clearable
        dense
        readonly
        :append-icon="null"
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
            <span class="pr-2">{{ item }}</span>
            <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </fieldset>
  </div>
</template>

<script>
import I18nCountries from "@/i18nCountries";
import { getOption } from "v-phone-input";
import { compareName } from "@/components/SortUtils";
import { mapGetters } from "vuex";

export default {
  name: "WhatsAppDeliveryMode",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    alert: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputWhatsappPhone: "",
      recipientWhatsappRules: [
        (v) => !!v || this.$t("alerts.wizard.deliveryMode.whatsapp.mandatoryMessage"),
        (v) => v.length > 0 || this.$t("alerts.wizard.deliveryMode.whatsapp.mandatoryMessage"),
      ],
      whatsAppPhoneErrorMessages: [],
    };
  },
  watch: {
    language() {
      this.onNewWhatsappPhone();
    },
  },
  computed: {
    ...mapGetters("user", ["language"]),
    recipientWhatsapp: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    allCountries() {
      const language = this.$i18n.locale;
      let translatedCountryNames = I18nCountries.getCountries(language);
      let countries = getOption("allCountries").map((countryInfo) => ({
        dialCode: countryInfo.dialCode,
        format: countryInfo.format,
        iso2: countryInfo.iso2,
        name: translatedCountryNames[countryInfo.iso2],
        priority: countryInfo.priority,
      }));
      return countries.sort(compareName);
    },
  },
  methods: {
    onNewWhatsappPhone() {
      if (
        !this.inputWhatsappPhone ||
        this.inputWhatsappPhone.length === 0 ||
        this.recipientWhatsapp.some((r) => r === this.inputWhatsappPhone)
      ) {
        return;
      }

      let phoneNumber = this.$refs.whatsAppPhone.lazyPhone;
      if (phoneNumber && phoneNumber.valid === false) {
        // The phone number `this.inputWhatsappPhone` is not valid. Build the error message!
        let example = this.$refs.whatsAppPhone.phoneExample;
        this.whatsAppPhoneErrorMessages = [
          this.$t("alerts.wizard.deliveryMode.whatsapp.invalidPhoneNumber", { example: example }),
        ];
      } else {
        this.whatsAppPhoneErrorMessages = [];
        this.recipientWhatsapp.push(this.inputWhatsappPhone);
        this.inputWhatsappPhone = "";
      }
    },
    onWhatsappPhoneKeyDown(event) {
      if (event.code === "Enter" || event.code === "NumpadEnter" || event.code === "Tab") {
        this.onNewWhatsappPhone();
      }
    },
    initializeData() {
      this.recipientWhatsapp = this.alert?.recipients_whats_app ?? [];
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-phone-input {
  display: flex;
  align-items: flex-start;
}
::v-deep .v-phone-input__country.v-input {
  min-width: 0;
  max-width: 5rem;
}
::v-deep .v-phone-input__phone.v-input {
  flex-grow: 1;
}
</style>
