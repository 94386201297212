import factory1 from "@/assets/animations/factory1.json";
import runningCorgi from "@/assets/animations/runningCorgi.json";

export default {
  namespaced: true,
  name: "eastereggs",
  state: {
    isAnimating: false,
    animationData: factory1,
    runningCorgiAnimation: runningCorgi,
    isAnimatingCorgi: false,
  },
  getters: {
    isAnimatingCorgi(state) {
      return state.isAnimatingCorgi;
    },
    runningCorgiAnimation(state) {
      return state.runningCorgiAnimation;
    },
    isAnimating(state) {
      return state.isAnimating;
    },
    animationData(state) {
      return state.animationData;
    },
  },
  mutations: {
    setIsAnimating(state, flag) {
      state.isAnimating = flag;
    },
    setAnimationData(state, data) {
      state.animationData = data;
    },
    setAnimatingCorgi(state, flag) {
      state.isAnimatingCorgi = flag;
    },
  },
  actions: {
    animateCorgi({ commit }) {
      commit("setAnimatingCorgi", false);
      commit("setAnimatingCorgi", true);
      setTimeout(() => {
        commit("setAnimatingCorgi", false);
      }, 4500);
    },
    completeCorgiAnimation({ commit }) {
      commit("setAnimatingCorgi", false);
    },
    startAnimating({ commit, state }) {
      const isAnimating = state.isAnimating;
      if (!isAnimating) {
        commit("setAnimationData", factory1);
        commit("setIsAnimating", true);
      } else {
        commit("setIsAnimating", false);
      }
    },
    completeAnimating({ commit }) {
      setTimeout(() => {
        commit("setIsAnimating", false);
      }, 750);
    },
  },
};
