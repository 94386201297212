<template>
  <div>
    <slot name="checkbox" />
    <fieldset v-if="isSelected" class="ml-10">
      <v-row v-for="(_, index) in teamsWebhooks" :key="'teamsWebhooks-' + index" class="teams-webhook-link mb-3">
        <v-col class="flex-grow-1">
          <wx-text-field
            v-model="teamsWebhooks[index]"
            :placeholder="$t('alerts.wizard.deliveryMode.teams.inputPlaceholder')"
            :rules="[() => validUrl(index)]"
            :dense="false"
            validate-on-blur
          />
        </v-col>
        <v-col cols="1" class="d-flex justify-end px-0 ma-0">
          <wx-btn-standard
            :disabled="isRemoveButtonDisabled()"
            class="btn-delete"
            text
            @click="deleteTeamsWebhook(index)"
          >
            <v-icon left>mdi-close</v-icon>
          </wx-btn-standard>
        </v-col>
      </v-row>
      <wx-btn-standard text @click="addNewTeamsWebhook" class="mt-3">
        <v-icon left>mdi-plus</v-icon>
        {{ $t("alerts.wizard.deliveryMode.teams.buttonAddNewWebhook") }}
      </wx-btn-standard>
    </fieldset>
  </div>
</template>

<script>
import WxTextField from "@/components/ui/WxTextField.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";

export default {
  name: "TeamsDeliveryMode",
  components: { WxBtnStandard, WxTextField },
  props: {
    value: {
      type: Array,
      default: () => [""],
    },
    alert: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      metricThreshold: 50,
    };
  },
  watch: {
    isSelected() {
      this.onTeamsWebhookSelectedChanged();
    },
  },
  computed: {
    teamsWebhooks: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    validUrl(teamWebhookIndex) {
      let url = this.teamsWebhooks[teamWebhookIndex];
      if (!url || url.length === 0) {
        return this.$t("alerts.wizard.deliveryMode.teams.invalidTeamsWebhook");
      }
      try {
        const validUrl = new URL(url);
        // Javascript considers the URL as valid. It cleans itself minor errors
        // (example: http:/\teams.com/channel1 is rewritten as http://teams.com/channel1)
        // Lets update the original URL by the re-written one.
        let t = [...this.teamsWebhooks];
        t[teamWebhookIndex] = validUrl.href;
        this.teamsWebhooks = t;
        return true;
      } catch (err) {
        return this.$t("alerts.wizard.deliveryMode.teams.invalidTeamsWebhook");
      }
    },
    onTeamsWebhookSelectedChanged() {
      if (this.isSelected && this.teamsWebhooks.length === 0) {
        this.teamsWebhooks.push("");
      }
    },
    isRemoveButtonDisabled() {
      return this.teamsWebhooks.length <= 1;
    },
    deleteTeamsWebhook(index) {
      this.teamsWebhooks.splice(index, 1);
    },
    addNewTeamsWebhook() {
      this.teamsWebhooks.push("");
    },
    initializeData() {
      this.teamsWebhooks = this.alert?.teams_webhooks ?? [];
    },
  },
};
</script>

<style scoped lang="scss">
.teams-webhook-link {
  max-height: 60px;
}
</style>
