<template>
  <LottieAnimation
    v-if="isAnimatingCorgi"
    :animation-data="runningCorgiAnimation"
    :auto-play="true"
    :loop="true"
    class="running-corgi"
    :speed="1"
    ref="anim"
  />
</template>
<script>
import LottieAnimation from "lottie-web-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { LottieAnimation },
  computed: {
    ...mapGetters("eastereggs", ["animationData", "isAnimating", "isAnimatingCorgi", "runningCorgiAnimation"]),
  },
  methods: {
    ...mapActions("eastereggs", ["completeAnimating", "completeCorgiAnimation"]),
  },
};
</script>
<style scoped lang="scss">
.hovering-anim-box {
  height: 200px;
  width: 200px;
  position: fixed;
  left: 50%;
  z-index: 1001;
}
.running-corgi {
  position: fixed;
  bottom: -20px; /* Adjust the distance from the bottom */
  left: -200px; /* Start off-screen */
  height: 200px;
  width: 200px;
  z-index: 800;
  animation: slide 3.1s linear; /* Slide animation */
}

@keyframes slide {
  0% {
    left: 100vw; /* End off-screen to the right */
  }
  100% {
    left: -200px; /* Start off-screen to the left */
  }
}
</style>
