<template>
  <wx-form-container class="wizard">
    <div class="d-flex justify-center mt-10" v-if="isFetchingAlert">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-form v-else ref="alertForm" lazy-validation>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.alertTypeTitle") }}</h3>
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.alertTypeInfo") }}</h5>
        <wx-autocomplete v-model="kpi" label="KPI" :items="kpiItems" :rules="kpiRules" return-object />
        <div v-if="kpi">
          <v-row>
            <v-col v-if="kpi.thresholdWithDuration || kpi.unplannedCurrentDowntime">
              <wx-autocomplete
                v-model="operator"
                :label="$t('alerts.wizard.operator')"
                :items="operatorItems"
                :rules="validateOperatorRules"
                :disabled="kpi.value === 'UnplannedCurrentDowntime'"
              />
            </v-col>
            <v-col v-if="kpi.thresholdWithDuration">
              <wx-text-field
                v-model="metricThreshold"
                label="Target"
                :rules="validateKpiValueRules"
                suffix="%"
                type="number"
                hide-spin-buttons
              />
            </v-col>
            <v-col :cols="kpi.cumulativeUptime ? '6' : null">
              <wx-timer-duration-input
                v-model="duration"
                :title="$t('alerts.wizard.duration')"
                :error-messages="[]"
                :rules="durationRules"
              />
            </v-col>
          </v-row>
          <h5 v-if="kpi && kpi.cumulativeUptime" class="font-weight-light mb-4">{{ $t("alerts.wizard.resetModeInfo") }}</h5>
          <v-row v-if="kpi.cumulativeUptime">
            <v-col cols="6">
              <wx-autocomplete
                v-model="uptimeResetMode"
                :label="$t('alerts.wizard.resetMode.label')"
                :items="cumulativeUptimeResetModes"
                :rules="uptimeResetModeRules"
              />
            </v-col>
          </v-row>
        </div>
      </fieldset>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.alertTitle") }}</h3>
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.alertTitleInfo") }}</h5>
        <wx-text-field v-model="title" :dense="false" :placeholder="defaultAlertTitle" />
      </fieldset>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.targetTitle") }}</h3>
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.targetInfo") }}</h5>
        <wx-production-unit-multi-selector
          v-model="productionUnits"
          :available-items="factoryProductionUnits"
          :is-multiple-factories="false"
        />
        <div v-if="isWorkShiftEnabled">
          <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.shiftInfo") }}</h5>
          <wx-production-unit-multi-selector
            v-model="workShifts"
            :label="$t('multiSelector.workShifts')"
            :available-items="workShiftItems"
            :is-multiple-factories="false"
          />
        </div>
      </fieldset>
      <fieldset>
        <h3 class="mb-2">{{ $t("alerts.wizard.deliveryMode.title") }}</h3>
        <h5 class="font-weight-light mb-4">{{ $t("alerts.wizard.deliveryMode.info") }}</h5>
        <!--    EMAIL    -->
        <EmailDeliveryMode
          ref="emailDeliveryMode"
          v-show="showEmailDeliveryMode"
          v-model="recipientEmails"
          :is-selected="emailSelected"
          :alert="alert"
        >
          <template #checkbox>
            <v-checkbox
              v-model="emailSelected"
              :label="$t('alerts.wizard.deliveryMode.email.name')"
              :rules="[validateAtLeastOneDeliveryMode]"
              class="mt-0"
            />
          </template>
        </EmailDeliveryMode>
        <!--    TEAMS    -->
        <TeamsDeliveryMode
          ref="teamsDeliveryMode"
          v-show="showTeamsDeliverMode"
          v-model="teamsWebhooks"
          :is-selected="teamsWebhookSelected"
          :alert="alert"
        >
          <template #checkbox>
            <v-checkbox
              v-model="teamsWebhookSelected"
              :label="$t('alerts.wizard.deliveryMode.teams.name')"
              :rules="[validateAtLeastOneDeliveryMode]"
              class="mt-0"
            />
          </template>
        </TeamsDeliveryMode>
        <!--    WHATS APP    -->
        <WhatsAppDeliveryMode
          ref="whatsAppDeliveryMode"
          v-show="showWhatsAppDeliveryMode"
          v-model="recipientWhatsapp"
          :is-selected="whatsAppSelected"
          :alert="alert"
        >
          <template #checkbox>
            <v-checkbox
              v-model="whatsAppSelected"
              :label="$t('alerts.wizard.deliveryMode.whatsapp.name')"
              :rules="[validateAtLeastOneDeliveryMode]"
              class="mt-0"
            />
          </template>
        </WhatsAppDeliveryMode>
        <!--    IN APP    -->
        <InAppDeliveryMode
          ref="inAppDeliveryMode"
          v-show="showInAppDeliveryMode"
          v-model="inAppRecipientUserIds"
          :is-selected="inAppSelected"
          :alert="alert"
        >
          <template #checkbox>
            <v-checkbox
              v-model="inAppSelected"
              label="Tileplus"
              :rules="[validateAtLeastOneDeliveryMode]"
              class="mt-0"
              disabled
            />
          </template>
        </InAppDeliveryMode>
      </fieldset>
      <fragment v-if="alert">
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.alertStatus") }}</h5>
        <v-switch v-model="enabled" :label="alertStatus" color="primary" hide-details inset />
      </fragment>
      <form-footer-actions :click-submit="submit" cancel-to="toAlerts" />
    </v-form>
  </wx-form-container>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete.vue";
import WxTimerDurationInput from "@/components/ui/WxTimerDurationInput.vue";
import WxFormContainer from "@/components/ui/WxFormContainer.vue";
import { mapActions, mapGetters } from "vuex";
import FormFooterActions from "@/components/ui/FormFooterActions.vue";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector.vue";
import AlertService from "@/components/alerts/AlertService";
import RouteService from "@/router/RouteService";
import WxTextField from "@/components/ui/WxTextField.vue";
import EmailDeliveryMode from "@/components/alerts/EmailDeliveryMode.vue";
import TeamsDeliveryMode from "@/components/alerts/TeamsDeliveryMode.vue";
import WhatsAppDeliveryMode from "@/components/alerts/WhatsAppDeliveryMode.vue";
import InAppDeliveryMode from "@/components/alerts/InAppDeliveryMode.vue";
import WorkShiftService from "@/components/shiftscheduler/WorkShiftService";

export default {
  name: "AlertWizard",
  components: {
    InAppDeliveryMode,
    WhatsAppDeliveryMode,
    TeamsDeliveryMode,
    EmailDeliveryMode,
    WxTextField,
    WxProductionUnitMultiSelector,
    FormFooterActions,
    WxFormContainer,
    WxTimerDurationInput,
    WxAutocomplete,
  },
  props: {
    alert: {
      type: Object,
      default: () => null,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFetchingAlert: false,
      kpi: null,
      kpiRules: [(v) => !!v || this.$t("alerts.wizard.kpiMandatoryMessage")],
      title: null,
      factoryWorkShifts: [],
      productionUnits: [],
      workShifts: [],
      enabled: true,
      duration: "00:15",
      metricThreshold: 50,
      operator: this.gteOperator,
      uptimeResetMode: "reset_on_shift_change",
      validateKpiValueRules: [
        (value) => (value && !isNaN(value)) || this.$t("alerts.wizard.metricThreshold.mandatoryMessage"),
      ],
      validateOperatorRules: [(value) => !!value || this.$t("alerts.wizard.operatorRequiredMessage")],
      // delivery modes
      emailSelected: false,
      recipientEmails: [],
      whatsAppSelected: false,
      recipientWhatsapp: [],
      teamsWebhookSelected: false,
      teamsWebhooks: [""],
      inAppSelected: false,
      inAppRecipientUserIds: [],
    };
  },
  watch: {
    activeFactory() {
      this.initializeData();
    },
    emailSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
    whatsAppSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
    teamsWebhookSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
    inAppSelected() {
      this.$refs.alertForm.resetValidation();
      this.$refs.alertForm.validate();
    },
    kpi() {
      if (this.kpi.value === "UnplannedCurrentDowntime") {
        this.operator = this.gteOperator.value;
      }
    },
    showInAppDeliveryMode(showInApp) {
      // For now, select in app automatically when cumulative uptime is selected since it is the only
      // delivery mode for this alert type.
      if (showInApp) {
        this.inAppSelected = true;
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["factoryUsers", "activeFactory"]),
    ...mapGetters("user", ["email", "language"]),
    alertStatus() {
      return this.enabled ? this.$t("user.statusActive") : this.$t("user.statusInactive");
    },
    isWorkShiftEnabled() {
      return this.kpi?.cumulativeUptime != null;
    },
    kpiItems() {
      return [
        // TODO: re-implement this when the backend is ready
        // {
        //   text: this.$t("alerts.types.timer"),
        //   value: "Recurring",
        //   recurringFrequency: true,
        // },
        // {
        //   divider: true,
        // },
        {
          text: this.$t("alerts.types.cumulativeUptime"),
          value: "CumulativeUptime",
          cumulativeUptime: true,
        },
        {
          text: this.$t("alerts.types.currentDowntime"),
          value: "UnplannedCurrentDowntime",
          unplannedCurrentDowntime: true,
        },
        {
          text: this.$t("alerts.types.Availability"),
          value: "Availability",
          thresholdWithDuration: true,
        },
        {
          text: this.$t("alerts.types.Performance"),
          value: "Performance",
          thresholdWithDuration: true,
        },
        {
          text: this.$t("alerts.types.Quality"),
          value: "Quality",
          thresholdWithDuration: true,
        },
        {
          text: this.$t("alerts.types.OEE"),
          value: "OEE",
          thresholdWithDuration: true,
        },
        {
          text: this.$t("alerts.types.OOE"),
          value: "OOE",
          thresholdWithDuration: true,
        },
        {
          text: this.$t("alerts.types.Giveaway"),
          value: "Giveaway",
          thresholdWithDuration: true,
        },
      ];
    },
    operatorItems() {
      const operators = [this.gteOperator];
      if (this.kpi.value !== "UnplannedCurrentDowntime") {
        operators.push(this.lteOperator);
      }
      return operators;
    },
    factoryProductionUnits() {
      return this.activeFactory ? this.activeFactory.productionUnits : [];
    },
    workShiftItems() {
      if (this.factoryWorkShifts.length === 0 || this.productionUnits.length === 0) return [];
      const worksShiftsFilteredByPu = this.factoryWorkShifts.filter((puWs) => !!this.productionUnits.find((pu) => pu.id === puWs.production_unit_id));
      const filteredWorkShifts = [];
      for (const puWs of worksShiftsFilteredByPu) {
        filteredWorkShifts.push(...puWs.work_shifts);
      }
      const uniqueFilteredWorkShifts = {};
      for (const ws of filteredWorkShifts) {
        uniqueFilteredWorkShifts[ws.id] = ws;
      }
      return Object.values(uniqueFilteredWorkShifts);
    },
    uptimeResetModeRules() {
      return [() => !!this.uptimeResetMode || "A reset mode must be selected."];
    },
    durationRules() {
      return [
        () => !!this.durationToMinutes || this.$t("alerts.wizard.kpiConsecutiveMinutesInvalid"),
        () => this.durationToMinutes >= 5 || this.$t("alerts.wizard.kpiConsecutiveMinutesMin", { minutes: 5 }),
        () => this.durationToMinutes <= 1440 || this.$t("alerts.wizard.kpiConsecutiveMinutesMax", { hours: 24 }),
      ];
    },
    durationToMinutes() {
      if (!this.duration || this.duration === "") return null;
      try {
        const destructuredTime = this.duration.split(":");
        const pattern = /\d{2}/;
        if (!destructuredTime[0]?.match(pattern) || !destructuredTime[1].match(pattern)) return null;
        const hours = parseInt(destructuredTime[0]);
        const minutes = parseInt(destructuredTime[1]);
        return hours * 60 + minutes;
      } catch (_err) {
        return null;
      }
    },
    gteOperator() {
      return { text: this.$t("alerts.operators.above"), value: "greater_than_or_equal_to" };
    },
    lteOperator() {
      return { text: this.$t("alerts.operators.below"), value: "less_than_or_equal_to" };
    },
    unplannedCurrentDowntimeDefaultTitle() {
      return this.$t("alerts.alertListItemLabels.currentDowntime", { minute: this.durationToMinutes });
    },
    metricThresholdDefaultTitle() {
      let operator = "{operator}";
      switch (this.operator) {
        case "greater_than_or_equal_to":
          operator = this.$t("alerts.alertListItemLabels.operators.above").toLocaleLowerCase();
          break;
        case "less_than_or_equal_to":
          operator = this.$t("alerts.alertListItemLabels.operators.below").toLocaleLowerCase();
          break;
      }
      const value = this.metricThreshold;
      const minute = this.durationToMinutes;
      const key = `alerts.alertListItemLabels.${this.kpi?.value}`;
      if (this.$te(key)) {
        // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
        return this.$t(key, { value, operator, minute });
      }
      return this.$t("alerts.alertListItemLabels.default"); // Fallback if key doesn't exist
    },
    recurringFrequencyTitle() {
      const minute = this.durationToMinutes;
      return this.$t("alerts.alertListItemLabels.recurringFrequency", { minute });
    },
    defaultAlertTitle() {
      if (this.kpi?.unplannedCurrentDowntime) return this.unplannedCurrentDowntimeDefaultTitle;
      if (this.kpi?.thresholdWithDuration) return this.metricThresholdDefaultTitle;
      if (this.kpi?.recurringFrequency) return this.recurringFrequencyTitle;
      return this.$t("alerts.alertListItemLabels.default"); // Fallback if key doesn't exist
    },
    showEmailDeliveryMode() {
      if (!this.kpi) return false;
      return this.kpi.cumulativeUptime == null;
    },
    showTeamsDeliverMode() {
      if (!this.kpi) return false;
      return this.kpi.cumulativeUptime == null;
    },
    showWhatsAppDeliveryMode() {
      if (!this.kpi) return false;
      return this.kpi.cumulativeUptime == null;
    },
    showInAppDeliveryMode() {
      if (!this.kpi) return false;
      return this.kpi.cumulativeUptime != null;
    },
    cumulativeUptimeResetModes() {
      return [
        {
          text: this.$t("alerts.wizard.resetMode.lineStart"),
          value: "reset_on_line_start",
        },
        {
          text: this.$t("alerts.wizard.resetMode.shiftChange"),
          value: "reset_on_shift_change",
        },
      ];
    },
  },
  methods: {
    ...mapActions("navigation", ["fetchFactoryUsers"]),
    ...mapActions("operation", ["showOperationSuccess", "handleHttpError"]),
    validateAtLeastOneDeliveryMode() {
      if (!this.emailSelected && !this.whatsAppSelected && !this.teamsWebhookSelected && !this.inAppSelected) {
        return this.$t("alerts.wizard.deliveryMode.mandatoryMessage");
      } else {
        return true;
      }
    },
    initializeFromAlertRules(alertData) {
      switch (this.kpi.value) {
        case "UnplannedCurrentDowntime":
          return this.initializeUnplannedCurrentDowntimeRules(alertData);
        case "CumulativeUptime":
          return this.initializeCumulativeUptimeRules(alertData);
        case "RecurringFrequency":
          return this.initializeRecurringFrequencyRules(alertData);
        case "Availability":
        case "Performance":
        case "Quality":
        case "OEE":
        case "OOE":
        case "Giveaway":
          return this.initializeThresholdWithDurationRules(alertData);
      }
    },
    initializeUnplannedCurrentDowntimeRules(alertData) {
      // need for backwards compatibility with old rules
      const rules = alertData.trigger_rules
        ? alertData.trigger_rules.unplanned_current_downtime
        : alertData.unplanned_current_downtime_rules;
      this.operator = rules.equality;
      this.duration = this.parseToUIduration(rules.duration_minutes);
    },
    initializeThresholdWithDurationRules(alertData) {
      // need for backwards compatibility with old rules
      const rules = alertData.trigger_rules
        ? alertData.trigger_rules.threshold_with_duration
        : alertData.threshold_with_duration_rules;
      this.operator = rules.equality;
      this.metricThreshold = rules.metric_threshold;
      this.duration = this.parseToUIduration(rules.duration_minutes);
    },
    initializeRecurringFrequencyRules(alertData) {
      const rules = alertData.trigger_rules.recurring_frequency;
      this.duration = rules.duration_minutes;
    },
    initializeCumulativeUptimeRules(alertData) {
      const rules = alertData.trigger_rules.cumulative_uptime;
      this.duration = this.parseToUIduration(rules.duration_minutes);
      this.uptimeResetMode = rules.cumulative_uptime_reset_mode;
    },
    async initializeData() {
      if (!this.activeFactory) return;
      await Promise.all([
        this.fetchWorkShifts(),
        this.fetchFactoryUsers(this.activeFactory.id)
      ]);
      this.$refs.emailDeliveryMode.initializeData();
      this.$refs.teamsDeliveryMode.initializeData();
      this.$refs.whatsAppDeliveryMode.initializeData();
      this.$refs.inAppDeliveryMode.initializeData();
      if (this.alert) {
        this.kpi = this.kpiItems.find((k) => k.value === this.alert.type);
        this.title = this.alert.title ?? null;
        this.productionUnits = this.alert.production_unit_ids?.map((puId) => this.factoryProductionUnits.find((pu) => pu.id === puId)).filter((pu) => !!pu) ?? [];
        this.workShifts = this.alert.work_shift_ids?.map((wsId) => this.workShiftItems.find((ws) => ws.id === wsId)).filter((ws) => !!ws) ?? [];
        this.initializeFromAlertRules(this.alert);
        this.enabled = this.alert.enabled;
        this.emailSelected = this.recipientEmails.length > 0;
        this.whatsAppSelected = this.recipientWhatsapp.length > 0;
        this.teamsWebhookSelected = this.teamsWebhooks.length > 0;
        this.inAppSelected = this.inAppRecipientUserIds.length > 0;
      } else {
        this.productionUnits = this.factoryProductionUnits;
        this.workShifts = this.workShiftItems;
      }
    },
    parseToUIduration(durationMinutes) {
      let hours = parseInt(durationMinutes / 60)
        .toString()
        .padStart(2, "0");
      let minutes = parseInt(durationMinutes % 60)
        .toString()
        .padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    submit() {
      if (!this.activeFactory || !this.$refs.alertForm.validate() || !this.kpi) return;
      const payload = this.buildPayload();
      if (!this.alert) {
        // create the new alert
        this.createAlert(payload);
      } else {
        // update the existing alert
        this.updateAlert(payload);
      }
    },
    updateAlert(payload) {
      AlertService.updateAlert(this.activeFactory.id, this.alert.id, payload)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertUpdateMessage"));
          this.$router.replace(RouteService.toAlerts());
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
          this.$router.replace(RouteService.toAlerts());
        });
    },
    createAlert(payload) {
      AlertService.createAlert(this.activeFactory.id, payload)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertCreationMessage"));
          this.$router.replace(RouteService.toAlerts());
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
          this.$router.replace(RouteService.toAlerts());
        });
    },
    appendRules(payload) {
      const rules = {
        duration_minutes: this.durationToMinutes,
      };

      if (this.kpi.unplannedCurrentDowntime) {
        // append Unplanned Current Downtime rules
        rules.equality = this.gteOperator.value;
        rules.metric_threshold = null;
        payload.trigger_rules = { unplanned_current_downtime: rules };
      } else if (this.kpi.thresholdWithDuration) {
        // append Metric Threshold rules
        rules.equality = this.operator;
        rules.metric_threshold = this.metricThreshold;
        payload.trigger_rules = { threshold_with_duration: rules };
      } else if (this.kpi.recurringFrequency) {
        // append Recurring Frequency rules
        rules.equality = null;
        rules.metric_threshold = null;
        payload.trigger_rules = { recurring_frequency: rules };
      } else if (this.kpi.cumulativeUptime) {
        // append Cumulative Uptime rules
        rules.equality = null;
        rules.metric_threshold = null;
        rules.cumulative_uptime_reset_mode = this.uptimeResetMode;
        payload.trigger_rules = { cumulative_uptime: rules };
      }
    },
    appendRecipientTypes(payload) {
      const teamsWebhookURLS = this.teamsWebhooks.filter((link) => link.length > 0);
      payload.recipients = this.emailSelected ? this.recipientEmails : null;
      payload.recipients_whats_app = this.whatsAppSelected ? this.recipientWhatsapp : null;
      payload.teams_webhooks = this.teamsWebhookSelected && teamsWebhookURLS.length > 0 ? teamsWebhookURLS : null;
      payload.in_app_user_ids = this.inAppSelected ? this.inAppRecipientUserIds : null;
    },
    appendTitle(payload) {
      if (!this.title || this.title.length === 0) {
        payload.title = this.defaultAlertTitle;
      } else {
        payload.title = this.title;
      }
    },
    buildPayload() {
      const payload = {
        type: this.kpi.value,
        enabled: this.enabled,
        production_unit_ids: this.productionUnits.map((pu) => pu.id),
        work_shift_ids: this.isWorkShiftEnabled ? this.workShifts.map((ws) => ws.id) : null,
      };
      this.appendTitle(payload);
      this.appendRecipientTypes(payload);
      this.appendRules(payload);
      return payload;
    },
    async fetchWorkShifts() {
      if (!this.activeFactory) return;
      return WorkShiftService.getWorkShifts(this.activeFactory.id)
        .then((response) => {
          this.factoryWorkShifts = response.data;
        })
        .catch(console.log);
    },
  },
  mounted() {
    this.initializeData();
  },
};
</script>
