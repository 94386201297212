<template>
  <div>
    <slot name="checkbox" />
    <fieldset v-if="isSelected" class="ml-10">
      <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.deliveryMode.email.info") }}</h5>
      <v-combobox
        v-model="recipientEmails"
        :filter="filterRecipient"
        :hide-no-data="!recipientEmailSearch"
        :items="recipientEmailItems"
        :search-input.sync="recipientEmailSearch"
        :error-messages="emailErrorMessages"
        :rules="recipientEmailRules"
        :label="$t('alerts.wizard.deliveryMode.email.inputLabel')"
        hide-selected
        multiple
        small-chips
        filled
        clearable
        dense
        @change="onEmailChange"
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
            <span class="pr-2">{{ item }}</span>
            <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
          </v-chip>
        </template>
      </v-combobox>
    </fieldset>
  </div>
</template>

<script>
import validations from "@/components/validations";
import { mapGetters } from "vuex";

export default {
  name: "EmailDeliveryMode",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    alert: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recipientEmailItems: [],
      recipientEmailSearch: null,
      emailErrorMessages: [],
      recipientEmailRules: [
        (v) => !!v || this.$t("alerts.wizard.deliveryMode.email.mandatoryMessage"),
        (v) => v.length > 0 || this.$t("alerts.wizard.deliveryMode.email.mandatoryMessage"),
      ],
    };
  },
  watch: {
    recipientEmails(val, prev) {
      if (val.length === prev.length) return;

      this.recipientEmails = val.map((v) => {
        this.recipientEmailItems.push(v);
        return v;
      });
    },
    userEmails() {
      this.recipientEmailItems = this.recipientEmails.concat(this.userEmails);
    },
  },
  computed: {
    ...mapGetters("navigation", ["factoryUsers"]),
    ...mapGetters("user", ["email"]),
    recipientEmails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    userEmails() {
      return this.factoryUsers.map((u) => u.email);
    },
  },
  methods: {
    filterRecipient(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    onEmailChange(value) {
      if (!value || value.length === 0) return;
      const lastEmailEntered = value[value.length - 1];
      if (validations.isUserEmailValid(lastEmailEntered) && this.emailErrorMessages.length > 0) {
        this.emailErrorMessages = [];
      } else if (!validations.isUserEmailValid(lastEmailEntered)) {
        this.emailErrorMessages = [this.$t("alerts.wizard.deliveryMode.email.invalidMessage")];
        this.recipientEmails.splice(value.length - 1, 1);
      }
    },
    initializeData() {
      this.recipientEmailItems = this.recipientEmails.concat(this.userEmails);
      if (this.alert) {
        this.recipientEmails = this.alert.recipients;
      } else {
        this.recipientEmails.push(this.email);
      }
    },
  },
};
</script>