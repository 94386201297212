<template>
  <v-card :style="[cardStyle, cardPositionStyle]" class="card">
    <v-card-title class="card-title">
      <!-- Left Section: Icon and Title -->
      <div class="left-section">
        <div class="icon-holder">
          <v-icon class="inner-icon" color="white">mdi-bell-ring-outline</v-icon>
        </div>
        <div class="title-text ml-5">
          <h3 class="mb-1">{{ item.title }}</h3>
          <p class="wx-typo-sm">{{ item.secondaryTitle }}</p>
        </div>
      </div>

      <!-- Top-Right Text -->
      <div class="top-right-text">
        <p class="mb-0 wx-typo-xs">{{ item.topRightText }}</p>
      </div>
    </v-card-title>

    <v-card-actions class="card-actions">
      <wx-btn-standard
        v-if="showButtons"
        color="secondary"
        class="font-weight-regular text-center"
        @click="processItem(cancelActionCallback)"
      >
        {{ cancelActionButtonText }}
      </wx-btn-standard>
      <wx-btn-standard
        color="primary"
        :class="completedButtonTextColor"
        @click="processItem(primaryActionCallback)"
        v-if="showButtons"
      >
        {{ primaryActionButtonText }}
      </wx-btn-standard>
    </v-card-actions>
  </v-card>
</template>

<script>
/**
 * NOTE: IF YOU CHANGE THE STYLE, ADD NEW ITEMS HERE
 * make sure you change the min-height of the .cards container (see StackedCardsOverlay.vue)
 *
 */
import WxBtnStandard from "@/components/ui/WxBtnStandard";

import { mapGetters } from "vuex";

export default {
  components: { WxBtnStandard },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    primaryActionButtonText: {
      type: String,
      default: "",
    },
    cancelActionButtonText: {
      type: String,
      default: "",
    },
    primaryActionCallback: {
      type: String,
      default: "",
    },
    cancelActionCallback: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    totalCards: {
      type: Number,
      default: 0,
    },
    raisedEffect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("user", ["theme", "isAccessible"]),
    cardPositionStyle() {
      if (!this.raisedEffect) {
        return {};
      }
      return {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
      };
    },
    completedButtonTextColor() {
      const baseClass = "font-weight-regular text-center mr-3";
      if (this.theme === "light" || this.isAccessible) {
        return `${baseClass} white--text`;
      } else {
        return `${baseClass} black--text`;
      }
    },
    cardStyle() {
      if (!this.raisedEffect) {
        return {};
      }

      const maxIndexForScaling = 3;
      const minScale = 0.75;
      const indexForScaling = Math.min(this.index, maxIndexForScaling - 1);

      const scale = 1 - indexForScaling * 0.039;
      const adjustedScale = Math.max(scale, minScale);

      const zIndex = this.totalCards - this.index;
      const translateY = -indexForScaling * 15;
      return {
        transform: `translate(0px, ${translateY}px) scale(${adjustedScale})`,
        filter: `brightness(${adjustedScale}) saturate(1.1)`,
        transformOrigin: "center bottom",
        zIndex: zIndex,
        width: "100%",
      };
    },
  },
  methods: {
    processItem(action) {
      const payload = {
        item: this.item,
        index: this.index,
      };
      this.$emit("itemProcessed", action, payload);
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: transform 0.3s, margin-top 0.3s;
  pointer-events: auto;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: flex-start; /* Aligns icon and title vertically */
  flex: 1; /* Allows it to take up remaining space */
  margin-right: 1rem; /* Provides space between left and right sections */
}

.icon-holder {
  width: 50px;
  height: 50px;
  border-radius: 0.5rem;
  background: var(--color-primary);
  border: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-icon {
  font-size: 30px;
}

.title-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title-text h3 {
  margin: 0;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-text p {
  margin: 0;
}

.top-right-text {
  text-align: right;
}

.top-right-text p {
  margin: 0;
}

.card-actions {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 4px;
  padding-right: 2px;
}

/* Media Queries for Responsiveness */
@media (min-width: 600px) {
  .title-text h3 {
    font-size: 0.5rem;
  }
}

@media (min-width: 900px) {
  .title-text h3 {
    font-size: 1rem;
  }
}
</style>
