<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card class="pa-5">
      <v-card-title>
        <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text class="pl-14">
        {{ content }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <wx-btn-standard color="secondary" @click="back">{{
            $t("dashboard.productSampling.submitSessionDialog.back")
          }}</wx-btn-standard>
        <wx-btn-standard color="primary" @click="submit">{{
            $t("dashboard.productSampling.submitSessionDialog.submit")
          }}</wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";

export default {
  components: { WxBtnStandard },
  name: "ConfirmSubmitDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    expectedSampleCount: {
      type: Number,
      required: true,
    },
    missingSampleCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isMissingSample() {
      return this.missingSampleCount > 0;
    },
    title() {
      if (this.isMissingSample) {
        return this.$t("dashboard.productSampling.submitSessionDialog.titleForMissingSamples", {
          count: this.missingSampleCount,
        });
      } else {
        return this.$t("dashboard.productSampling.submitSessionDialog.title");
      }
    },
    content() {
      if (this.isMissingSample) {
        return this.$t("dashboard.productSampling.submitSessionDialog.contentForMissingSamples", {
          actualCount: this.missingSampleCount,
          expectedCount: this.expectedSampleCount,
        });
      } else {
        return this.$t("dashboard.productSampling.submitSessionDialog.content");
      }
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: var(--color-base-background);
}
</style>
