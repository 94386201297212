<template>
  <wx-autocomplete
    v-model="selectedItems"
    :items="sortedAvailableItems"
    item-text="name"
    item-value="id"
    :label="label ?? $t('multiSelector.productionUnits')"
    :rules="[() => validateItems()]"
    :clearable="clearable"
    multiple
    return-object
    @change="emitSelection"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggleItemSelection" :dense="true">
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ $t("common.all") }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" :disabled="item.disabled" :dense="true">
        <v-list-item-icon v-bind="attrs">
          <v-icon v-if="attrs.inputValue">mdi-checkbox-marked</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="isMultipleFactories">{{ `${item.factory} / ${item.name}` }}</span>
            <span v-else>{{ item.name }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ index, item }">
      <div v-if="allItemsSelected(index)" class="mt-2">
        <span>{{ $t("common.all") }}</span>
      </div>
      <div v-if="isLessThanMaxChipCount()">
        <v-chip
          v-if="index < maxNumberOfChips"
          label
          small
          :close="!isItemDisabled(item.id)"
          color="primary"
          class="my-1"
          @click:close="removeByIndex(index)"
        >
          <span> {{ selectedItems[index].name }} </span>
        </v-chip>
        <v-chip
          v-else-if="index === maxNumberOfChips"
          label
          small
          close
          color="primary"
          @click:close="removeByIndex(index)"
        >
          <span>{{ $t("common.numberOfChipsRemaining", [selectedItems.length - maxNumberOfChips]) }}</span>
        </v-chip>
      </div>
    </template>
  </wx-autocomplete>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import { compareName, sortProductionUnitsByDisabledStatus } from "@/components/SortUtils";

export default {
  name: "WxProductionUnitMultiSelector",
  components: {
    WxAutocomplete,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isMultipleFactories: {
      type: Boolean,
      required: true,
    },
    availableItems: {
      type: Array,
      required: true,
    },
    maxNumberOfChips: {
      type: Number,
      default: () => 6,
    },
    acceptEmptySelection: {
      type: Boolean,
      default: () => false,
    },
    disabledItems: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedItems: this.value,
    };
  },
  watch: {
    value() {
      this.selectedItems = this.value;
    },
    selectedItems() {
      this.emitSelection();
    },
    availableItems() {
      this.selectedItems = this.selectedItems.filter((pu1) => !!this.availableItems.find((pu2) => pu1.id === pu2.id));
    },
  },
  computed: {
    icon() {
      if (
        this.selectedItems &&
        this.selectedItems.length === this.availableItems.length
      ) {
        return "mdi-close-box";
      } else {
        return "mdi-checkbox-blank-outline";
      }
    },
    sortedAvailableItems() {
      if (this.availableItems && this.availableItems.length > 0) {
        let puList = [...this.availableItems];
        // sort first by name
        puList.sort(compareName);
        // set the disabled status
        puList.map((pu) => (pu.disabled = this.isItemDisabled(pu.id)));
        // sort by disabled status
        puList.sort(sortProductionUnitsByDisabledStatus);
        return puList;
      }
      return [];
    },
  },
  methods: {
    validateItems() {
      if (!this.selectedItems || this.selectedItems.length === 0) {
        if (!this.acceptEmptySelection) {
          return this.$t("multiSelector.errors.noItems");
        }
      }
      return true;
    },
    toggleItemSelection() {
      this.$nextTick(() => {
        if (this.selectedItems.length === this.availableItems.length) {
          this.selectedItems = [...this.disabledItems];
        } else {
          this.selectedItems = [...this.availableItems];
        }
      });
    },
    isItemDisabled(puId) {
      return this.disabledItems.findIndex((pu) => pu.id === puId) >= 0;
    },
    allItemsSelected(index) {
      return index === 0 && this.selectedItems.length === this.availableItems.length;
    },
    isLessThanMaxChipCount() {
      return this.selectedItems.length < this.availableItems.length;
    },
    removeByIndex(index) {
      let toRemove = this.selectedItems[index];
      if (toRemove) {
        let newList = [];
        this.selectedItems.forEach((pu) => {
          if (pu !== toRemove) {
            newList.push(pu);
          }
        });
        this.selectedItems = newList;
      }
    },
    emitSelection() {
      this.$emit("input", this.selectedItems);
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss" scoped>
.pu-as-chips {
  min-height: 40px;
}
</style>
