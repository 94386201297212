import http from "../../BaseServiceHttp";
import axios from "axios";
import { getTokenRedirect } from "@/authentication/authRedirect";
import { tokenRequest } from "@/authentication/authConfig";

class DowntimeReasonService {
  getDowntimeReasonById(downtimeReasonId) {
    return http.get(`downtime-reasons/${downtimeReasonId}`);
  }
  getDowntimeReasonTree(factoryId) {
    return http.get(`/factories/${factoryId}/downtime-reasons-tree`);
  }
  createCategory(factoryId, newCategory) {
    return http.post(`/factories/${factoryId}/downtime-reason-categories`, newCategory);
  }
  updateCategory(categoryId, updatedCategory) {
    return http.put(`/downtime-reason-categories/${categoryId}`, updatedCategory);
  }
  createReason(factoryId, newReason) {
    return http.post(`/factories/${factoryId}/downtime-reasons`, newReason);
  }
  updateReason(reasonId, updatedReason) {
    return http.put(`/downtime-reasons/${reasonId}`, updatedReason);
  }
  deleteCategory(downtimeCategoryId) {
    return http.delete(`/downtime-reason-categories/${downtimeCategoryId}`);
  }
  deleteReason(downtimeReasonId) {
    return http.delete(`/downtime-reasons/${downtimeReasonId}`);
  }
  // Utility functions
  extractReasonsFromTree(downtimeReasonTree, destinationList) {
    downtimeReasonTree.forEach((element) => {
      if (element.type === "category") {
        if (element.children.length > 0) {
          this.extractReasonsFromTree(element.children, destinationList);
        }
      } else {
        if (element.planned) {
          destinationList.push(element);
        }
      }
    });
  }
  downloadReasonTree(factoryId) {
    const httpForExport = axios.create({
      baseURL: process.env.VUE_APP_DASHBOARD_API_BASE_URL,
      method: "POST",
      responseType: "blob",
    });
    httpForExport.interceptors.request.use(async (req) => {
      const token = await getTokenRedirect(tokenRequest);
      req.headers.Authorization = "Bearer " + token;
      if (req.data) req.headers["Content-Type"] = "application/json";
      return req;
    });
    return httpForExport.get(`factories/${factoryId}/downtime-reason-tree/export`);
  }
  uploadReasonTree(factoryId, file) {
    let formData = new FormData();
    formData.append("file", file);
    return http.post(`/factories/${factoryId}/downtime-reason-tree/import`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }
}

export default new DowntimeReasonService();
