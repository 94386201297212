<template>
  <div class="overlay">
    <div class="stacks-wrapper">
      <div class="stacks-container">
        <wx-btn-standard
          outlined
          title="Ignore All"
          @click="handleClearAll"
          :style="ignoreAllButtonStyle"
          v-if="showButtons"
        >
          <p class="wx-typo-xs mb-0"> {{ $t("alerts.buttons.clearAll") }} </p>
        </wx-btn-standard>
        <GroupStack
          id="in-app-alerts"
          v-for="(group, index) in visibleGroupedItems"
          :key="group.key"
          :group="group"
          :show-buttons="showButtons"
          :is-top-stack="index === 0"
          :primary-action-button-text="primaryActionButtonText"
          :cancel-action-button-text="cancelActionButtonText"
          :cancel-action-callback="cancelActionCallback"
          :primary-action-callback="primaryActionCallback"
          @handleItemProcessed="handleItemProcessed"
          @groupFinished="groupItems"
        >
          <template v-slot:default="slotProps">
            <slot v-bind="slotProps"></slot>
          </template>
        </GroupStack>
      </div>
    </div>
  </div>
</template>

<script>
import GroupStack from "./GroupStack.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard";

export default {
  name: "StackedCardsOverlay",
  components: { GroupStack, WxBtnStandard },
  props: {
    items: {
      type: Map,
      required: true,
    },
    primaryActionButtonText: {
      type: String,
      default: "",
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    cancelActionButtonText: {
      type: String,
      default: "",
    },
    primaryActionCallback: {
      type: String,
      default: "",
    },
    cancelActionCallback: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxVisibleGroups: 1,
      groupedItems: [],
    };
  },
  computed: {
    visibleGroupedItems() {
      return this.groupedItems.slice(0, this.maxVisibleGroups);
    },
    topGroupCount() {
      const topGroupCardCount = this.groupedItems[0]?.items.length || 0;
      const maxCardsInTopGroup = 3;
      return Math.min(topGroupCardCount, maxCardsInTopGroup);
    },
    ignoreAllButtonStyle() {
      let bottomMargin = 10 * this.topGroupCount;
      return {
        "margin-bottom": `${bottomMargin}px`,
        "align-self": "flex-end",
        border: "none",
        "border-radius": "8px",
        cursor: "pointer",
        "background-color": "var(--color-neutral)",
        "pointer-events": "auto",
      };
    },
  },
  created() {
    this.groupItems();
    this.calculateMaxVisibleGroups();
    window.addEventListener("resize", this.calculateMaxVisibleGroups);
  },
  watch: {
    items: {
      handler() {
        this.groupItems();
      },
      deep: true,
    },
  },
  methods: {
    handleItemProcessed(action, item) {
      this.$emit("handleItemProcessed", action, item);
    },
    calculateMaxVisibleGroups() {
      const availableHeight = window.innerHeight - 250;
      const cardHeight = 150;

      const clearAllButtonHeight = 50;
      const adjustedAvailableHeight = availableHeight - clearAllButtonHeight;

      const topGroupHeight = this.topGroupCount * 1.25; //25% for the top stack adjustment

      // Spacing between groups
      const spacingBetweenGroups = 20;

      // Initialize total height with the top group height
      let totalHeight = topGroupHeight;
      let maxGroups = 1;

      if (this.groupedItems.length > 1) {
        totalHeight += spacingBetweenGroups;
      }

      for (let i = 1; i < this.groupedItems.length; i++) {
        const newTotalHeight = totalHeight + cardHeight + spacingBetweenGroups;

        if (newTotalHeight <= adjustedAvailableHeight) {
          totalHeight = newTotalHeight;
          maxGroups += 1;
        } else {
          break;
        }
      }

      // Update the maxVisibleGroups data property
      this.maxVisibleGroups = maxGroups;
    },
    groupItems() {
      this.groupedItems = Array.from(this.items, ([key, items]) => ({ key, items }));
    },
    handleClearAll() {
      this.$emit("clearAll");
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateMaxVisibleGroups);
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  z-index: 7;
  padding: 20px;
  pointer-events: none; /* Pass mouse clicks through */
}

.stacks-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.stacks-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Align stacks to the right */
  margin-top: auto;
  /* Push stacks to the bottom */
  margin-bottom: 10px;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .stacks-wrapper {
    align-items: center;
  }

  .stacks-container {
    align-items: center;
  }
}
</style>
