<template>
  <transition name="overlayFade" appear>
    <stacked-cards-overlay
      v-if="showAlertsOverlay"
      ref="alertsOverlay"
      :items="activeAlertInstances"
      :primary-title="primaryTitle"
      :secondary-title="secondaryTitle"
      :top-right-text="topRightText"
      :show-buttons="showAlertsOverlayButtons"
      :primary-action-button-text="primaryActionButtonText"
      :cancel-action-button-text="cancelActionButtonText"
      :cancel-action-callback="cancelActionCallback"
      :primary-action-callback="primaryActionCallback"
      @handleItemProcessed="handleItemProcessed"
      @clearAll="clearAll"
    />
  </transition>
</template>

<script>
import WebPubSubService from "@/components/WebPubSubService";
import StackedCardsOverlay from "@/components/ui/stackablecards/StackedCardsOverlay";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { StackedCardsOverlay },
  name: "AlertInstanceOverlay",
  data() {
    return {
      notificationGroupKey: this.notificationGroupingKey,
    };
  },
  methods: {
    ...mapActions("alerts", [
      "fetchAlertInstances",
      "ignoreAlertInstance",
      "completeAlertInstance",
      "ignoreAllAlertInstances",
      "setBufferingState",
      "startAutoIgnore",
      "stopAutoIgnore",
    ]),
    primaryTitle(item) {
      return item.title;
    },
    secondaryTitle(item) {
      return item.productionUnitName;
    },
    topRightText(item) {
      return item.dateTime;
    },
    notificationGroupingKey(item) {
      return `${item.productionUnitId}:${item.alertId}`;
    },
    handleItemProcessed(action, payload) {
      const alertPayload = payload.item;
      switch (action) {
        case "ignore":
          this.ignoreAlertInstance({ alertPayload: alertPayload });
          break;
        case "markAsCompleted":
          this.completeAlertInstance({ alertPayload: alertPayload });
          break;
        default:
          console.warn("An invalid action type was submitted.");
          break;
      }
    },
    clearAll() {
      this.ignoreAllAlertInstances();
    },

    attachSubElementHoverListeners() {
      const childRootEl = this.$refs.alertsOverlay?.$el;
      if (!childRootEl) return;
      const subElements = childRootEl.querySelectorAll(".group-stack");
      if (!subElements.length) return;

      // Store references so we can remove listeners later
      this._attachedDivs = Array.from(subElements);

      // 3) Attach event listeners to each element
      this._attachedDivs.forEach((el) => {
        el.addEventListener("mouseenter", this.onMouseEnter);
        el.addEventListener("mouseleave", this.onMouseLeave);
      });
    },
    detachSubElementHoverListeners() {
      if (this._attachedDivs && this._attachedDivs.length) {
        this._attachedDivs.forEach((el) => {
          el.removeEventListener("mouseenter", this.onMouseEnter);
          el.removeEventListener("mouseleave", this.onMouseLeave);
        });
      }
    },
    onMouseEnter() {
      this.setBufferingState({ isBuffering: true });
    },
    onMouseLeave() {
      this.setBufferingState({ isBuffering: false });
    },
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "isPresenter"]),
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("alerts", ["activeAlertInstances"]),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    showAlertsOverlayButtons() {
      return !this.isPresenter;
    },
    showAlertsOverlay() {
      if (!this.activeAlertInstances) {
        return false;
      }
      const currentRoute = this.$route.name?.toLowerCase();
      const nonOverlayableRoutes = ["root", "home", "styleguide", "termsofservice"];
      return this.activeAlertInstances.size > 0 && !nonOverlayableRoutes.includes(currentRoute);
    },
    primaryActionButtonText() {
      return this.$t("alerts.buttons.markAsCompleted");
    },
    cancelActionButtonText() {
      return this.$t("alerts.buttons.ignore");
    },
    cancelActionCallback() {
      return "ignore";
    },
    primaryActionCallback() {
      return "markAsCompleted";
    },
  },
  watch: {
    activeFactory() {
      const factoryId = this.activeFactory?.id;
      if (factoryId) {
        this.fetchAlertInstances();
        WebPubSubService.disconnect();
        WebPubSubService.setupWebSocket();
      }
    },
  },
  mounted() {
    this.attachSubElementHoverListeners();
    this.startAutoIgnore();
  },
  beforeDestroy() {
    this.detachSubElementHoverListeners();
    this.stopAutoIgnore();
  },
};
</script>
