<template>
  <fragment>
    <wx-form-container class="wizard">
      <v-form ref="rejectReasonForm" lazy-validation>
        <fieldset>
          <legend class="legend-header top-legend">{{ $t("common.information") }}</legend>
          <v-row>
            <v-col cols="12" sm="6">
              <!-- - - - - - - - - - - -       Code       - - - - - - - - - - - - - - -->
              <wx-text-field
                v-model.trim="reasonCode"
                :label="$t('rejectReason.reasonCode') + ' *'"
                :rules="[() => validateCode()]"
                :counter="rejectReasonCodeMaxLength"
                :maxlength="rejectReasonCodeMaxLength"
                :error="reasonCodeError"
                :error-messages="reasonCodeErrorMessages"
              />
            </v-col>
            <v-col cols="12">
              <!-- - - - - - - - - - - -       Name       - - - - - - - - - - - - - - -->
              <wx-text-field
                v-model.trim="reasonName"
                :rules="[() => validateName()]"
                :label="$t('rejectReason.reasonName') + ' *'"
                :counter="rejectReasonNameMaxLength"
                :maxlength="rejectReasonNameMaxLength"
              />
            </v-col>
            <v-col cols="12">
              <!-- - - - - - - - - - - -     Production Units     - - - - - - - - - - - - - - -->
              <wx-production-unit-multi-selector
                v-model="selectedProductionUnits"
                :is-multiple-factories="false"
                :available-items="sortedProductionUnits"
              />
            </v-col>
          </v-row>
        </fieldset>
        <form-footer-actions :click-submit="submit" :click-cancel="back" />
      </v-form>
    </wx-form-container>
  </fragment>
</template>

<script>
import { compareName } from "@/components/SortUtils";
import { mapActions, mapGetters } from "vuex";
import ErrorHandling from "@/components/ErrorHandling";
import FormFooterActions from "@/components/ui/FormFooterActions";
import RejectReasonService from "@/components/rejectreason/RejectReasonService";
import RouteService from "@/router/RouteService";
import Validations from "@/components/validations";
import WxFormContainer from "@/components/ui/WxFormContainer";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector";
import WxTextField from "@/components/ui/WxTextField";

export default {
  name: "RejectReasonWizard",
  components: {
    FormFooterActions,
    WxFormContainer,
    WxProductionUnitMultiSelector,
    WxTextField,
  },
  props: {
    parentCategoryId: {
      type: String,
      required: true,
    },
    initialReason: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      id: null,
      reasonCode: null,
      reasonName: null,
      reasonCodeError: false,
      reasonCodeErrorMessages: [],
      selectedProductionUnits: [],
      rejectReasonCodeMaxLength: Validations.rejectReasonCodeMaxLength,
      rejectReasonNameMaxLength: Validations.rejectReasonNameMaxLength,
    };
  },
  watch: {
    initialReason() {
      if (this.initialReason) {
        this.id = this.initialReason.id;
        this.reasonCode = this.initialReason.code;
        this.reasonName = this.initialReason.name;
        if (this.initialReason.production_unit_ids) {
          let puList = [];
          this.initialReason.production_unit_ids.forEach((puId) => {
            const pu = this.activeFactoryProductionUnits.find((pu) => pu.id === puId);
            if (pu) {
              puList.push(pu);
            }
          });
          this.selectedProductionUnits = puList;
        } else {
          this.selectedProductionUnits = [];
        }
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId", "activeFactoryProductionUnits"]),
    ...mapGetters("user", ["theme"]),
    reasonCodeLabel() {
      return this.$t("rejectReason.reasonCode") + " *";
    },
    reasonNameLabel() {
      return this.$t("rejectReason.reasonName") + " *";
    },
    sortedProductionUnits() {
      if (this.activeFactoryProductionUnits && this.activeFactoryProductionUnits.length > 0) {
        let puList = [...this.activeFactoryProductionUnits];
        puList.sort(compareName);
        return puList;
      }
      return [];
    },
  },
  methods: {
    ...mapActions("dashboard", ["fetchAvailableRejectReasons"]),
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    getRouteBack() {
      return RouteService.toRejectReasons(this.parentCategoryId);
    },
    back() {
      return this.$router.push(this.getRouteBack());
    },
    validateCode() {
      if (!this.reasonCode) {
        return this.$t("rejectReason.errors.noReasonCode");
      }
      if (this.reasonCode.trim().length > this.rejectReasonCodeMaxLength) {
        return this.$t("rejectReason.errors.reasonCodeTooLong");
      }
      if (!Validations.isRejectReasonCodeValid(this.reasonCode)) {
        return this.$t("rejectReason.errors.reasonCodeHasInvalidChar");
      }
      this.reasonCodeError = false;
      this.reasonCodeErrorMessages = [];
      return true;
    },
    validateName() {
      if (!this.reasonName) {
        return this.$t("rejectReason.errors.noReasonName");
      }
      if (this.reasonName.trim().length > this.rejectReasonNameMaxLength) {
        return this.$t("rejectReason.errors.reasonNameTooLong");
      }
      if (!Validations.isRejectReasonNameValid(this.reasonName)) {
        return this.$t("rejectReason.errors.reasonNameHasInvalidChar");
      }
      return true;
    },
    validateProductionUnits() {
      if (!this.selectedProductionUnits || this.selectedProductionUnits.length === 0) {
        return this.$t("rejectReason.errors.noProductionUnits");
      }
      return true;
    },
    isCreation() {
      return this.id === null;
    },
    isEdition() {
      return this.id !== null;
    },
    submit() {
      if (this.parentCategoryId && this.parentCategoryId.length > 0) {
        if (this.isCreation()) {
          this.create();
        }
        if (this.isEdition()) {
          this.update();
        }
      } else {
        let messages = [
          this.$t("rejectReason.errors.noParentCategory") +
            " " +
            this.$t("rejectReason.errors.reasonNoParentCategoryHint"),
        ];
        this.showOperationError(messages);
      }
    },
    create() {
      const isValid = this.$refs.rejectReasonForm.validate();
      if (isValid && this.parentCategoryId) {
        let newReason = {
          code: this.reasonCode,
          name: this.reasonName,
          category_id: this.parentCategoryId,
          production_unit_ids: this.selectedProductionUnits.map((pu) => pu.id),
        };
        RejectReasonService.createReason(this.activeFactoryId, newReason)
          .then((response) => this.handleReasonCreationResponse(response, newReason))
          .catch((error) => this.handleReasonEditionError(error.response));
      }
    },
    handleReasonCreationResponse(httpResponse, newReason) {
      if (httpResponse.status === 201) {
        this.fetchAvailableRejectReasons();
        this.showOperationSuccess(
          this.$t("rejectReason.successfullyReasonCreated", {
            code: newReason.code,
            name: newReason.name,
          }),
        );
        this.$router.push(this.getRouteBack());
      }
    },
    update() {
      const isValid = this.$refs.rejectReasonForm.validate();
      if (isValid && this.parentCategoryId) {
        let updatedReason = {
          id: this.id,
          code: this.reasonCode,
          name: this.reasonName,
          category_id: this.parentCategoryId,
          production_unit_ids: this.selectedProductionUnits.map((pu) => pu.id),
        };
        RejectReasonService.updateReason(this.id, updatedReason)
          .then((response) => this.handleReasonUpdateResponse(response, updatedReason))
          .catch((error) => this.handleReasonEditionError(error.response));
      }
    },
    handleReasonUpdateResponse(httpResponse, updatedReason) {
      if (httpResponse.status === 200) {
        this.fetchAvailableRejectReasons();
        this.showOperationSuccess(
          this.$t("rejectReason.successfullyReasonUpdated", {
            code: updatedReason.code,
            name: updatedReason.name,
          }),
        );
        this.$router.push(this.getRouteBack());
      }
    },
    handleReasonEditionError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      if (httpResponse.status === 409) {
        this.reasonCodeError = true;
        this.reasonCodeErrorMessages = errorMessage;
      } else {
        this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      }
    },
    getErrorMessage(code) {
      switch (code) {
        case "CS_REJR_PUT_20001":
        case "CS_REJR_POST_20001": {
          return this.$t("rejectReason.errors.duplicateReason");
        }
        default: {
          return this.$t("common.errors.default", { code: code });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
}

.form-box {
  max-width: 70%;
  padding: var(--box-padding-admin);
  background-color: var(--color-element-layer1);
  box-shadow: var(--box-shadow-small-elevation);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border-theme);

  h2 {
    margin-bottom: 20px;
  }
}

::v-deep legend.v-label {
  font-size: 1rem;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

::v-deep .v-input--radio-group--column .v-input--radio-group__input {
  align-items: flex-start;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
