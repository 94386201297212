<template>
  <v-app id="wxapp" :class="{ presenter: isPresenter && isDashboardUrl }" :language="this.language">
    <animations />
    <alert-instance-overlay v-if="!isMobile" />
    <component :is="layout" v-bind="layoutProps" />
    <v-snackbar
      v-model="snackBar.show"
      :timeout="snackBar.timeout"
      :color="snackBar.status"
      min-width="300"
      outlined
      text
      absolute
      center
      top
    >
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex flex-column">
          <span class="font-weight-bold">{{ snackBar.title }}</span>
          <div
            v-for="(message, index) in snackBar.messages"
            :key="`message-index-${index}`"
            class="mt-2"
            v-html="message"
          >
          </div>
        </div>
        <v-btn icon @click="hideOperation" class="ml-5">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AlertInstanceOverlay from "@/components/alerts/AlertInstanceOverlay.vue";
import Animations from "@/components/eastereggs/Animations.vue";
import keyboardEventListenerMixin from "@/mixins/KeyboardEventListenerMixin";

const default_layout = "default-layout";

export default {
  mixins: [keyboardEventListenerMixin],
  components: { Animations, AlertInstanceOverlay },
  metaInfo() {
    return {
      title: this.$t("meta.title"),
      htmlAttrs: {
        lang: this.language,
      },
      meta: [{ name: "description", content: this.$t("meta.description") }],
    };
  },
  data() {
    return {
      refreshPackageDefinitionsInterval: null,
    };
  },
  computed: {
    ...mapGetters("user", [
      "isPresenter",
      "language",
      "theme",
      "email",
      "isLoggedIn",
      "username",
      "loggedInUserRole",
      "accountId",
      "userId",
      "account",
    ]),
    ...mapGetters("dashboard", ["currentProductionUnitState"]),
    ...mapGetters("operation", ["snackBar"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    layoutProps() {
      return this.$route.meta.layoutProps;
    },
    activeTheme() {
      return this.theme;
    },
    isDashboardUrl() {
      return this.$route.path.includes("dashboard");
    },
  },

  watch: {
    isLoggedIn() {
      this.initializePendoSession();
    },
    account() {
      this.initializePendoSession();
    },
    activeTheme() {
      this.$vuetify.theme.dark = this.theme === "dark";
    },
    currentProductionUnitState: {
      immediate: true,
      handler(newValue) {
        let root = document.documentElement;

        switch (newValue) {
          case "connection_issues":
            root.style.setProperty("--color-pu-current-state", "var(--color-disconnected)");
            break;
          case "unknown":
            root.style.setProperty("--color-pu-current-state", "var(--color-unknown)");
            break;
          case "out_of_production":
            root.style.setProperty("--color-pu-current-state", "var(--color-outOfProduction)");
            break;
          case "up":
            root.style.setProperty("--color-pu-current-state", "var(--color-uptime)");
            break;
          case "down_unjustified":
            root.style.setProperty("--color-pu-current-state", "var(--color-unjustifiedDowntime)");
            break;
          case "down_unplanned":
            root.style.setProperty("--color-pu-current-state", "var(--color-justifiedDowntime)");
            break;
          case "down_planned":
            root.style.setProperty("--color-pu-current-state", "var(--color-plannedDowntime)");
            break;
          default:
            root.style.setProperty("--color-pu-current-state", "white");
        }
      },
    },
  },
  methods: {
    ...mapActions("operation", ["hideOperation"]),
    ...mapActions("packages", ["fetchPackageDefinitions"]),
    initializePendoSession() {
      if (!this.isLoggedIn || !this.account) return;
      // need to suppress since pendo is installed via script in index.html therefore eslint can't find it
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          id: this.userId,
          email: this.email,
          full_name: this.username,
          role: this.loggedInUserRole,
        },
        account: {
          id: this.account.id,
          name: this.account.name,
          insights_enabled: this.account.insights_enabled,
          is_tilelytics: this.account.is_tilelytics,
          domain_name: this.account.domain_name,
        },
      });
    },
  },
  mounted() {
    this.initializePendoSession();
    clearInterval(this.refreshPackageDefinitionsInterval);
    this.fetchPackageDefinitions();
    this.refreshPackageDefinitionsInterval = setInterval(() => this.fetchPackageDefinitions(), 3600000); // refresh every hour
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  &#wxapp {
    // gradient is set within layouts
    background-color: var(--color-base-theme);
  }

  .overlayFade-enter-active,
  .overlayFade-leave-active {
    transition: opacity 0.3s ease;
  }
  .overlayFade-enter,
  .overlayFade-leave-to {
    opacity: 0;
  }
  /** - - - - -
   * Presenter
   */
  &.presenter {
    padding: var(--grid-gutter);
    background-color: var(--color-pu-current-state);
    position: relative;
    transition: background-color 0.3s linear;
    overflow: hidden;

    &:after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: var(--grid-gutter) solid var(--color-pu-current-state);
      z-index: 6;
      pointer-events: none;
      transition: border-color 0.3s linear;
    }

    ::v-deep .v-application--wrap {
      min-height: calc(100vh - var(--grid-gutter) * 2);
    }

    ::v-deep .v-navigation-drawer {
      max-height: calc(100% - var(--grid-gutter) * 2) !important;
      left: var(--grid-gutter) !important;
      top: var(--grid-gutter) !important;
    }
  }
}
</style>
