<template>
  <v-menu offset-y nudge-bottom="8" v-model="menuState" :close-on-content-click="false" transition="slide-y-transition" z-index="1500">
    <!-- Control -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :title="`${displayName} — ` + $t('mainHeader.settings')"
        class="activator-btn"
        icon
        large
      >
        <!--
        if email = logged in
        -->
        <v-avatar v-if="email" class="activator">{{ avatarText }}</v-avatar>
        <v-icon v-if="!email">mdi-cog</v-icon>
      </v-btn>
    </template>

    <!-- Content -->
    <v-container tag="article" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
      <header v-if="displayName">
        <div class="user-profile">
          <div>
            <v-avatar color="primary" size="56">{{ avatarText }}</v-avatar>
          </div>
          <div class="mt-3">
            <span class="wx-typo-sm">{{ displayName }}</span>
          </div>
          <div class="mt-1">
            <span class="wx-typo-sm">{{ emailAddress }}</span>
          </div>
          <div class="mt-1">
            <span class="wx-typo-sm font-weight-light">{{ role }}</span>
          </div>
        </div>
        <v-divider class="my-3"></v-divider>
      </header>

      <!-- Language -->
      <h5 class="mb-2">{{ $t("settings.language") }}</h5>
      <wx-select v-model="selectedLanguage" :items="availableLanguages" />

      <!-- Theme Light/Dark -->
      <h5 class="mb-2">{{ $t("settings.theme") }}</h5>
      <v-btn-toggle class="toggle mb-2" dense borderless>
        <wx-btn-standard dense :color="isLightTheme ? 'primary' : 'secondary'" @click="setTheme('light')">
          {{ $t("settings.light") }}
        </wx-btn-standard>
        <wx-btn-standard dense :color="isDarkTheme ? 'primary' : 'secondary'" @click="setTheme('dark')">
          {{ $t("settings.dark") }}
        </wx-btn-standard>
        <wx-btn-standard dense :color="isAccessibleTheme ? 'primary' : 'secondary'" @click="setTheme('accessible')">
          {{ $t("settings.accessible") }}
        </wx-btn-standard>
      </v-btn-toggle>

      <!-- Fullscreen tv-mode -->
      <wx-btn-standard
        v-if="isPresenter && !isMobile"
        @click="toggleFullscreen"
        :title="$t('settings.fullscreenHoverTitle')"
        :color="fullscreenState ? 'primary' : 'secondary'"
        :class="{ active: fullscreenState }"
        class="mt-1 mb-3"
        dense
        block
      >
        {{ $t("settings.fullscreen") }}
      </wx-btn-standard>

      <!--
      if email = logged in
      -->
      <footer v-if="email" class="mb-3">
        <!-- Sign Out -->
        <wx-btn-standard @click="logout" class="mt-7" color="secondary" outlined block>
          {{ $t("settings.signOut") }}
        </wx-btn-standard>

        <!-- Terms of Service -->
        <wx-btn-standard :to="getTermsRoute" @click="closeMenu" class="mt-3" color="secondary" text block>
          {{ $t("settings.termsOfService") }}
        </wx-btn-standard>

        <h6 class="d-flex justify-center secondary--text">{{ versionNumber }}</h6>
      </footer>

      <div v-if="!email" class="spacer pt-2" />
    </v-container>
  </v-menu>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import { setThemeToLocalStorage } from "@/components/Theme";
import { mapGetters, mapActions } from "vuex";
import { setLanguageToLocalStorage } from "@/i18n";
import RouteService from "@/router/RouteService";
import * as authRedirect from "../authentication/authRedirect";
import UserService from "@/components/user/UserService";
import webPubSubService from "@/components/WebPubSubService";
import WxSelect from "@/components/ui/WxSelect.vue";

export default {
  name: "MainSettings",
  components: { WxSelect, WxBtnStandard },
  data() {
    return {
      menuState: false,
      selectedLanguage: "en",
      fullscreenState: false,
    };
  },
  watch: {
    selectedLanguage() {
      this.setLocale(this.selectedLanguage);
    },
  },
  computed: {
    ...mapGetters("user", [
      "username",
      "email",
      "isPresenter",
      "loggedInUserRole",
      "applicationVersion",
      "theme",
      "isAccessible",
    ]),
    availableLanguages() {
      return [
        {
          text: this.$t("settings.english"),
          value: "en",
        },
        {
          text: this.$t("settings.francais"),
          value: "fr",
        },
        {
          text: this.$t("settings.espanol"),
          value: "es",
        },
        {
          text: this.$t("settings.chinese"),
          value: "zh",
        },
      ];
    },
    isLightTheme() {
      return this.theme === "light" && !this.isAccessible;
    },
    isDarkTheme() {
      return this.theme === "dark";
    },
    isAccessibleTheme() {
      return this.isAccessible;
    },
    versionNumber() {
      return `v${this.applicationVersion}`;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    getTermsRoute() {
      return RouteService.toTermsOfService();
    },
    isHomeUrl() {
      return this.$route.path.includes("home");
    },
    avatarText() {
      return UserService.getAvatarText(this.username);
    },
    displayName() {
      return this.username ? this.username : "";
    },
    emailAddress() {
      return this.email ? this.email : "";
    },
    role() {
      if (this.loggedInUserRole) {
        return UserService.getDisplayRoleNameForValue(this.loggedInUserRole);
      }
      return "";
    },
  },
  methods: {
    ...mapActions("user", ["updateTheme", "updateLanguage"]),
    ...mapActions("overview", ["setIsFullScreen"]),
    setTheme(newTheme) {
      this.$vuetify.theme.dark = newTheme === "dark";
      setThemeToLocalStorage(this.$vuetify.theme.dark);
      this.updateTheme(newTheme);
    },
    setLocale(newLocal) {
      this.$i18n.locale = newLocal;
      setLanguageToLocalStorage(newLocal);
      this.updateLanguage(newLocal);
    },
    toggleFullscreen() {
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        var docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullscreen) {
          docElm.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
      }
    },
    updateFullscreenState() {
      this.fullscreenState =
        document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? true : false;
      this.setIsFullScreen(this.fullscreenState);
    },
    logout() {
      authRedirect.signOut();
      webPubSubService.disconnect();
      this.closeMenu();
    },
    closeMenu() {
      this.menuState = false;
    },
  },
  mounted() {
    this.language = this.$i18n.locale;
    this.selectedLanguage = this.language;
    this.updateFullscreenState();
    window.addEventListener("fullscreenchange", this.updateFullscreenState);
  },
  beforeDestroy() {
    window.removeEventListener("fullscreenchange", this.updateFullscreenState);
  },
};
</script>

<style lang="scss" scoped>
.activator-btn {
  background-color: var(--color-control-background-theme);
}

// Vuetify's <v-menu> wrapper
.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}
.container {
  padding-top: 20px;
  width: 300px;
  background-color: var(--color-flat-panel-theme);
}

.v-btn {
  font-size: 0.8125rem;

  // termsOfService
  &.v-btn--text {
    font-weight: 400; /* Regular */
    color: var(--color-text-subtle-theme) !important;
    text-decoration: underline;
  }
}
.v-item-group.v-btn-toggle {
  width: 100%;
  & > .v-btn {
    flex: 1;
    // fix a vuetify glitch where after 2nd click an active button looks darker.
    opacity: 1;
    &.v-btn--active {
      &:hover::before,
      &::before {
        opacity: 0;
      }
    }
    // when active color is primary and UX = disable
    &.primary {
      pointer-events: none;
      cursor: auto;
    }
  }
}

.user-profile {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
</style>
