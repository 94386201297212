import { mapGetters, mapActions } from "vuex";
import { setThemeToLocalStorage } from "@/components/Theme";
import RouteService from "@/router/RouteService";
import { compareName } from "@/components/SortUtils";

const themes = ["light", "dark", "accessible"];

export default {
  data() {
    return {
      productionUnitIndex: 0,
      secretCodes: [
        {
          code: [
            "arrowup",
            "arrowup",
            "arrowdown",
            "arrowdown",
            "arrowleft",
            "arrowright",
            "arrowleft",
            "arrowright",
            "b",
            "a",
          ],
          action: "activateKonamiCode",
        },
        {
          code: ["."],
          action: "toggleThemeRight",
        },
        {
          code: [","],
          action: "toggleThemeLeft",
        },
      ],
      inputSequences: [],
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("dashboard", ["activeProductionUnitId"]),
    ...mapGetters("user", ["isPresenter", "loggedInUserRole", "theme", "isAccessible"]),
    isLightTheme() {
      return this.theme === "light" && !this.isAccessible;
    },
    isDarkTheme() {
      return this.theme === "dark";
    },
    themeIndex() {
      if (this.isLightTheme) return 0;
      if (this.isDarkTheme) return 1;
      if (this.isAccessible) return 2;
      return -1;
    },
    sortedProductionUnits() {
      if (this.activeFactoryProductionUnits && this.activeFactoryProductionUnits.length > 0) {
        const puList = [...this.activeFactoryProductionUnits];
        puList.sort(compareName);
        return puList;
      }
      return [];
    },
  },
  watch: {
    activeProductionUnitId() {
      const puIndex = this.sortedProductionUnits?.findIndex((pu) => pu.id === this.activeProductionUnitId);
      if (puIndex >= 0) {
        this.productionUnitIndex = puIndex;
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  methods: {
    ...mapActions("eastereggs", ["startAnimating", "animateCorgi"]),
    ...mapActions("user", ["updateTheme"]),
    ...mapActions("dashboard", ["setActiveProductionUnitId"]),
    isTyping(event) {
      const target = event.target;
      const tagName = target.tagName.toLowerCase();
      return tagName === "input" || tagName === "textarea" || target.isContentEditable;
    },
    handleKeydown(event) {
      if (this.isTyping(event)) return;

      const key = event.key.toLowerCase();
      if (key === "escape") {
        this.inputSequences = [];
        return;
      }
      if ((event.ctrlKey || event.metaKey) && key === "arrowup") {
        this.inputSequences = [];
        this.cycleProductionUnitPageUp();
        return;
      }
      if ((event.ctrlKey || event.metaKey) && key === "arrowdown") {
        this.inputSequences = [];
        this.cycleProductionUnitPageDown();
        return;
      }

      this.inputSequences.push(key);
      const matchingCodes = this.secretCodes.filter((codeObj) =>
        this.isSequencePrefix(this.inputSequences, codeObj.code),
      );

      if (matchingCodes.length === 0) {
        this.inputSequences = [];
        return;
      }

      for (const codeObj of matchingCodes) {
        if (this.isSequenceEqual(this.inputSequences, codeObj.code)) {
          if (typeof this[codeObj.action] === "function") {
            this[codeObj.action]();
          }
          this.inputSequences = [];
          break;
        }
      }

      const maxCodeLength = Math.max(...this.secretCodes.map((codeObj) => codeObj.code.length));
      if (this.inputSequences.length > maxCodeLength) {
        this.inputSequences.shift();
      }
    },
    isSequencePrefix(inputSequences, secretCode) {
      return inputSequences.every((key, index) => key === secretCode[index]);
    },
    isSequenceEqual(inputSequences, secretCode) {
      return (
        inputSequences.length === secretCode.length && inputSequences.every((key, index) => key === secretCode[index])
      );
    },
    activateKonamiCode() {
      console.log("woof woof");
      this.animateCorgi();
    },
    mod(n, m) {
      return ((n % m) + m) % m;
    },
    cycleProductionUnitPageUp() {
      this.toggleProductionUnitPage(false);
    },
    cycleProductionUnitPageDown() {
      this.toggleProductionUnitPage(true);
    },
    toggleProductionUnitPage(isCyclingDown) {
      if (!this.sortedProductionUnits || this.sortedProductionUnits.length === 0) return;

      const nextProductionUnit = this.getNextValue(isCyclingDown, this.productionUnitIndex, this.sortedProductionUnits);
      const activeProductionUnitId = nextProductionUnit.id;
      this.setActiveProductionUnitId(activeProductionUnitId);
      this.$router.push(RouteService.toDashboard(activeProductionUnitId, "timeline"));
    },
    toggleThemeRight() {
      this.triggerThemeWithIncrement(true);
    },
    toggleThemeLeft() {
      this.triggerThemeWithIncrement(false);
    },
    triggerThemeWithIncrement(isPositive) {
      const nextTheme = this.getNextValue(isPositive, this.themeIndex, themes);
      this.$vuetify.theme.dark = nextTheme === "dark";
      setThemeToLocalStorage(this.$vuetify.theme.dark);
      this.updateTheme(nextTheme);
    },
    getNextValue(isPositive, currentIndex, array) {
      return array[this.mod(currentIndex + (isPositive ? 1 : -1), array.length)];
    },
  },
};
