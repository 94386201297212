/* eslint-disable prettier/prettier */
import i18n from "@/i18n";
import { getUnitLabel } from "@/UnitUtils";

class Validations {
  productionUnitNameMaxLength = 100;

  getProductionUnitAvailableUnits() {
    return [
      { id: "unit", name: i18n.t("common.units.unit"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "can", name: i18n.t("common.units.can"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "bottle", name: i18n.t("common.units.bottle"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "tray", name: i18n.t("common.units.tray"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "pack", name: i18n.t("common.units.pack"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "box", name: i18n.t("common.units.box"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "case", name: i18n.t("common.units.case"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "cycle", name: i18n.t("common.units.cycle"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "bag", name: i18n.t("common.units.bag"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "panel", name: i18n.t("common.units.panel"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "pallet", name: i18n.t("common.units.pallet"), isUnitChainConversion: true, isCount: true, isWeight: false, isVolume: false, isLength: false, unit: "count" },
      { id: "kilogram", name: i18n.t("common.units.kilogram"), isUnitChainConversion: false, isCount: false, isWeight: true, isVolume: false, isLength: false, unit: "kg" },
      { id: "ton", name: i18n.t("common.units.ton"), isUnitChainConversion: false, isCount: false, isWeight: true, isVolume: false, isLength: false, unit: "mt" },
      { id: "pound", name: i18n.t("common.units.pound"), isUnitChainConversion: false, isCount: false, isWeight: true, isVolume: false, isLength: false, unit: "lb" },
      { id: "ounce", name: i18n.t("common.units.ounce"), isUnitChainConversion: false, isCount: false, isWeight: true, isVolume: false, isLength: false, unit: "oz" },
      { id: "meter", name: i18n.t("common.units.meter"), isUnitChainConversion: false, isCount: false, isWeight: false, isVolume: false, isLength: true, unit: "m" },
      { id: "feet", name: i18n.t("common.units.feet"), isUnitChainConversion: false, isCount: false, isWeight: false, isVolume: false, isLength: true, unit: "ft" },
      { id: "liter", name: i18n.t("common.units.liter"), isUnitChainConversion: false, isCount: false, isWeight: false, isVolume: true, isLength: false, unit: "l" },
    ];
  }
  getWeightUnits() {
    return this.getProductionUnitAvailableUnits().filter((u) => u.isWeight);
  }
  getLengthUnits() {
    return this.getProductionUnitAvailableUnits().filter((u) => u.isLength);
  }
  getVolumeUnits() {
    return this.getProductionUnitAvailableUnits().filter((u) => u.isVolume);
  }
  getDefaultUnit() {
    return "unit";
  }
  isProductionUnitNameValid(value) {
    return /^(?:(?![×Þß÷þø])[-_ 0-9a-zÀ-ÿ])+$/.test(String(value).toLowerCase());
  }
  isProductionUnitObjectiveValid(value) {
    return /^[1-9]{1}\d{0,1}[.]{0,1}\d{0,1}$/.test(value);
  }
  isProductionUnitQualityValid(value) {
    return /^[0-9]{1}\d{0,1}[.]{0,1}\d{0,1}$/.test(value);
  }
  isProductionUnitConvertedUnitValid(unitId) {
    const found = this.getProductionUnitAvailableUnits().filter((u) => unitId === u.id);
    return found && found.length === 1;
  }

  downtimeCategoryCodeMaxLength = 5;
  downtimeCategoryNameMaxLength = 100;
  downtimeReasonCodeMaxLength = 100;
  downtimeReasonNameMaxLength = 200;

  isDowntimeCategoryCodeValid(value) {
    return /^[a-zA-Z0-9-_/|()[\]{}<>]+$/.test(String(value));
  }
  isDowntimeCategoryNameValid(value) {
    const withoutEmojis = this.getValueWithoutEmojis(value);
    if (value.length > 0 && withoutEmojis.length == 0) {
      return true;
    }
    return /^(?:(?![×Þß÷þø])[-_.,'\\/#%&| 0-9a-zÀ-ÿ()[\]{}<>œ…°])+$/.test(String(withoutEmojis).toLowerCase());
  }
  getValueWithoutEmojis(value) {
    return value.replace(
      /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu,
      "",
    );
  }

  rejectCategoryCodeMaxLength = 5;
  rejectCategoryNameMaxLength = 100;
  rejectReasonCodeMaxLength = 5;
  rejectReasonNameMaxLength = 100;
  rejectCommentMaxLength = 300;

  isRejectCategoryCodeValid(value) {
    return /^[a-zA-Z0-9-_/|()[\]{}<>]+$/.test(String(value));
  }
  isRejectCategoryNameValid(value) {
    const withoutEmojis = this.getValueWithoutEmojis(value);
    if (value.length > 0 && withoutEmojis.length == 0) {
      return true;
    }
    return /^(?:(?![×Þß÷þø])[-_.,'\\/#%&| 0-9a-zÀ-ÿ()[\]{}<>œ…°])+$/.test(String(withoutEmojis).toLowerCase());
  }
  isRejectReasonCodeValid(value) {
    return /^[a-zA-Z0-9-_/|()[\]{}<>]+$/.test(String(value));
  }
  isRejectReasonNameValid(value) {
    const withoutEmojis = this.getValueWithoutEmojis(value);
    if (value.length > 0 && withoutEmojis.length == 0) {
      return true;
    }
    return /^(?:(?![×Þß÷þø])[-_.,'\\/#%&| 0-9a-zÀ-ÿ()[\]{}<>œ…°])+$/.test(String(withoutEmojis).toLowerCase());
  }

  productSkuMaxLength = 50;
  productNameMaxLength = 100;
  productCategoryMaxLength = 100;
  productConversionFactorMaxLength = 10;
  productObjectiveMaxLength = 5;

  isProductConversionFactorValid(value) {
    return /^(?=.{1,10}$)\d{1,10}(\.\d{1,8})?$/.test(value);
  }
  isProductRateObjectiveValueValid(value) {
    return /^[0-9]+(\.\d+)?$/.test(value);
  }
  isProductGiveawayObjectiveValueValid(value) {
    return /^[0-9]{1,9}([.]\d{0,3}){0,1}$/.test(value);
  }
  isProductTargetValueValid(value) {
    return /^[0-9]{1,5}([.]\d{0,3}){0,1}$/.test(value);
  }
  getProductAvailableTargetUnits() {
    return [
      ...this.getWeightAvailableTargetUnits(),
      ...this.getVolumeAvailableTargetUnits(),
      ...this.getLengthAvailableTargetUnits(),
    ];
  }
  getWeightAvailableTargetUnits() {
    return [
      { id: "kg", name: getUnitLabel("kg") },
      { id: "lb", name: getUnitLabel("lb") },
      { id: "g", name: getUnitLabel("g") },
      { id: "oz", name: getUnitLabel("oz") },
    ];
  }
  getVolumeAvailableTargetUnits() {
    return [
      { id: "L", name: getUnitLabel("l") },
      { id: "mL", name: getUnitLabel("ml") },
    ];
  }
  getLengthAvailableTargetUnits() {
    return [
      { id: "m", name: getUnitLabel("m") },
      { id: "ft", name: getUnitLabel("ft") },
    ];
  }
  isProductTargetUnitValid(unitId) {
    const found = this.getProductAvailableTargetUnits().filter((u) => unitId === u.id);
    return found && found.length === 1;
  }

  userEmailMaxLength = 100;
  userDisplayNameMaxLength = 100;
  userPasswordMaxLength = 100;

  isUserEmailValid(value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(value).toLowerCase(),
    );
  }
  isUserDisplayNameValid(value) {
    return /^(?:(?![×Þß÷þø])[- '`0-9a-zÀ-ÿ])+$/.test(String(value).toLowerCase());
  }
  isUserPasswordValid(value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,16}$/.test(value);
  }

  isWorkShiftNameValid(value) {
    return /^(?:(?![×Þß÷þø])[-_ 0-9a-zÀ-ÿ])+$/.test(String(value).toLowerCase());
  }
};

export default new Validations();