import http from "../BaseServiceHttp";
import store from "@/store";
const { WebPubSubClient } = require("@azure/web-pubsub-client");

class WebPubSubService {
  isManuallyDisconnecting;
  client;
  constructor() {
    this.isManuallyDisconnecting = false;
  }
  getWebSocketURL() {
    return http.get("/websocket/url");
  }
  setupWebSocket() {
    this.getWebSocketURL()
      .then(async (response) => {
        await this.connect(response.data.url);
      })
      .catch((err) => {
        console.warn(err);
      });
  }
  async connect(url) {
    if (this.client) {
      console.warn("Web PubSub client already connected.");
      return;
    }

    const clientOptions = {
      autoReconnect: true,
      retryOptions: {
        maxRetries: 5, // Number of retry attempts
        retryDelayInMs: 3000, // Delay between retries in milliseconds
      },
    };
    this.client = new WebPubSubClient(url, clientOptions);

    // Register WebSocket event listeners

    // If you need to create a new handler for connected events, just register to the "connected" event
    // example  this.client.on("connected", () => { foo(); }
    this.client.on("disconnected", async () => {
      console.log("disconnected");
      // handle token expiration
      if (!this.isManuallyDisconnecting) {
        this.setupWebSocket();
      }
    });

    this.client.on("error", (err) => {
      console.error(err);
    });

    // Handle group messages
    this.client.on("group-message", (e) => {
      this.routeMessage(e.message);
    });
    await this.client.start();
  }
  routeMessage(message) {
    const data = message.data;
    if (data) {
      switch (data.type) {
        case "ALERT_UPDATED":
        case "ALERT_RAISED":
          store.dispatch("alerts/fetchAlertInstances");
          break;
        case "ALERT_DELETED":
          store.dispatch("alerts/handleAlertDeletion", data);
          break;
        case "ALERT_COMPLETED":
          store.dispatch("alerts/handleAlertInstanceCompletion", data);
          break;
        default:
          console.warn("Unhandled message type:", data.type);
      }
    }
  }

  async disconnect() {
    if (this.client) {
      await this.client.stop();
      this.client = null;
    }
  }
}

export default new WebPubSubService();
