import http from "../../BaseServiceHttp";

class AlertService {
  getAlerts(factoryId) {
    return http.get(`factories/${factoryId}/alerts`);
  }
  getAlert(factoryId, alertId) {
    return http.get(`factories/${factoryId}/alerts/${alertId}`);
  }
  createAlert(factoryId, payload) {
    return http.post(`factories/${factoryId}/alerts`, payload);
  }
  deleteAlert(factoryId, alertId) {
    return http.delete(`factories/${factoryId}/alerts/${alertId}`);
  }
  updateAlert(factoryId, alertId, payload) {
    return http.put(`factories/${factoryId}/alerts/${alertId}`, payload);
  }
  completeAlertInstance(productionUnitId, id) {
    return http.post(`production-units/${productionUnitId}/alert-instances/${id}/complete`);
  }
  ignoreAlertInstance(productionUnitId, id) {
    return http.post(`production-units/${productionUnitId}/alert-instances/${id}/ignore`);
  }
  ignoreAllAlertInstances(factoryId) {
    return http.post(`factory/${factoryId}/alert-instances/ignore`);
  }
  fetchInAppAlertInstances(factoryId) {
    return http.get(`factory/${factoryId}/alert-instances`);
  }
}

export default new AlertService();
