/**
 * Merges new items into an existing transformed map of lists grouped by compound keys.
 *
 * @param {Map<string, Array>} existingAlertsMap - The existing transformed map.
 * @param {Array} newAlertInstances - The incoming array of new items to merge.
 * @returns {Map<string, Array>} - The updated map with merged items.
 */
export function mergeAlertInstances(existingAlertsMap, newAlertInstances) {
  const createKey = (item) => `${item.productionUnitId}:${item.alertId}`;

  const sortedAlertInstances = newAlertInstances.sort((a, b) => b.raisedTime - a.raisedTime);

  if (!existingAlertsMap || existingAlertsMap.size === 0) {
    existingAlertsMap = new Map();
  }
  sortedAlertInstances.forEach((newItem) => {
    const key = createKey(newItem);
    if (!existingAlertsMap.has(key)) {
      existingAlertsMap.set(key, [newItem]);
    } else {
      const existingItems = existingAlertsMap.get(key);
      let existingItemIndex = existingItems.findIndex((item) => item.id === newItem.id);
      if (existingItemIndex > -1)
        existingItems[existingItemIndex] = newItem;
      else
        existingItems.push(newItem);
    }
  });

  for (const [, items] of existingAlertsMap) {
    items.sort((a, b) => b.raisedTime - a.raisedTime);
  }

  return sortByFirstItemInTheList(existingAlertsMap);
}

export function sortByFirstItemInTheList(map) {
  // Convert the Map to an array of [key, value] and sort
  const sortedEntries = Array.from(map.entries()).sort(([, itemsA], [, itemsB]) => {
    // We want to sort by the first item in the list
    const firstItemA = itemsA[0].raisedTime;
    const firstItemB = itemsB[0].raisedTime;
    // Ascending by earliest time
    return firstItemA - firstItemB;
  });
  return new Map(sortedEntries);
}
