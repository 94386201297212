<template>
  <v-dialog :wxid="$options.name" v-model="dialog" max-width="var(--modal-window-narrow-width)">
    <template #activator="{ on }">
      <wx-btn-standard
        v-on="on"
        :title="$t('common.delete')"
        color="error"
        outlined
      >
        {{ $t("common.delete") }}
      </wx-btn-standard>
    </template>

    <v-card :wxid="$options.name" tag="article" class="wx-confirmation-dialog-card">
      <v-card-title tag="header">
        <v-icon class="mb-3 mb-md-4 mb-xl-6">mdi-delete-outline</v-icon>
        <h3 class="heading">
          {{ $t("dashboard.productionRun.deleteProductionRun.questionConfirmToDelete") }}
          <q>{{ productLabel }}</q
          >?
        </h3>
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" icon="mdi-alert-circle-outline" text outlined
            >{{ $t("dashboard.productionRun.deleteProductionRun.deletionDescription") }}
          </v-alert>
        <p v-if="activeProductionUnit.production_unit_group_id">
          <v-checkbox
            v-model="forwardProductionUnitGroup"
            :label="$t('dashboard.productionRun.productionUnitGroupsCheckbox')"
            class="mt-0"
          />
        </p>
        <div v-if="!activeProductionUnit.production_unit_group_id || !forwardProductionUnitGroup">
          <div class="font-italic body-1 text-left">
            {{ $t("dashboard.productionRun.dropdownProductionUnitGroupsLabel") }}
          </div>

          <wx-production-unit-multi-selector
            v-model="selectedProductionUnits"
            :available-items="activeFactoryProductionUnits"
            :is-multiple-factories="false"
            :disabled-items="[activeProductionUnit]"
            :clearable="false"
            class="mt-1"
          />
        </div>
      </v-card-text>
      <v-card-actions tag="footer" class="d-flex flex-button-column-gap">
        <wx-btn-standard
          @click="dialog = false"
          :title="$t('deletionConfirmationDialog.cancelBtnHint')"
          class="cancel-btn flex-grow-1"
          large
        >
          {{ $t("common.cancel") }}
        </wx-btn-standard>
        <wx-btn-standard
          @click="submitDeletion"
          :title="$t('deletionConfirmationDialog.deleteBtnHint')"
          color="error"
          class="flex-grow-1"
          large
        >
          {{ $t("dashboard.productionRun.deleteProductionRun.deleteBtn") }}
        </wx-btn-standard>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductionRunService from "@/components/dashboard/productionRun/ProductionRunService";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import ErrorHandling from "@/components/ErrorHandling";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "DeleteProductionRunDialog",
  components: {
    WxBtnStandard,
    WxProductionUnitMultiSelector,
  },
  props: {
    productionRun: {
      type: Object,
      required: true,
    },
    productLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      selectedProductionUnits: [],
      forwardProductionUnitGroup: false,
    };
  },
  methods: {
    ...mapActions("dashboard", ["deleteProductionRun"]),
    ...mapActions("operation", ["showOperationError"]),
    setForwardedProductionUnits() {
      this.selectedProductionUnits = ProductionRunService.getForwardedProductionUnits(
        this.activeProductionUnit,
        this.activeFactoryProductionUnits,
      );
    },
    closeModalDialog() {
      this.$emit("close");
      this.dialog = false;
    },
    submitDeletion() {
      const forwardedProductionUnitIdsAsCsv = !this.forwardProductionUnitGroup ? ProductionRunService.toForwardedProductionUnitIdsAsCsv(
        this.activeProductionUnit,
        this.selectedProductionUnits,
      ) : null;

      const deletionRequest = {
        productionUnitEventId: this.productionRun.event_id,
        forwardedProductionUnitIds: forwardedProductionUnitIdsAsCsv,
        forwardedProductionUnitGroup: this.forwardProductionUnitGroup,
      };

      this.deleteProductionRun(deletionRequest)
        .then(this.closeModalDialog)
        .catch((error) => {
          this.showOperationError(
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              if (code === "DSH_PRODRUN_DELETE_10013") {
                const sku = this.productionRun.sku
                  ? this.productionRun.sku
                  : i18n.t("dashboard.panelHeader.unspecifiedProduct");
                return i18n.t("dashboard.productionRun.errors.productNotFound", { sku: sku });
              }
              if (code === "DSH_PRODRUN_DELETE_10012") {
                return i18n.t("dashboard.productionRun.errors.noProductionRunFound");
              }
              if (code === "DSH_PRODRUN_DELETE_1003") {
                return i18n.t("user.errors.puNotFound");
              }
              if (code === "DSH_PRODRUN_DELETE_10016") {
                return i18n.t("dashboard.productionRun.errors.productionUnitsNotSameFactory");
              }
              return i18n.t("common.errors.default", { code: code });
            }),
          );
        });
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryProductionUnits"]),
    ...mapGetters("dashboard", ["activeProductionUnit"]),
  },
  mounted() {
    this.forwardProductionUnitGroup = this.activeProductionUnit.production_unit_group_id ? true : false;
    this.setForwardedProductionUnits();
  },
};
</script>

<style lang="scss" scoped>
.wx-confirmation-dialog-card {
  &.v-card {
    position: relative;
    max-width: var(--modal-window-medium-width);
    padding: var(--dialog-padding);
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-lg);
    opacity: 1;

    .v-card__text {
      font-size: var(--font-size-norm);
    }
    .heading,
    p {
      text-align: center;
    }

    &,
    .v-icon,
    .v-card__text,
    .v-card__actions,
    .v-expansion-panel-content {
      color: var(--color-text-theme);
    }

    .v-card {
      // Card Header
      &__title {
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: var(--dialog-padding);
        word-break: break-word;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
