import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./modules/dashboard";
import user from "./modules/user";
import operation from "./modules/operation";
import overview from "./modules/overview";
import packages from "./modules/packages";
import navigation from "@/store/modules/navigation";
import productAdmin from "@/store/modules/productAdmin";
import devicesAdmin from "@/store/modules/devicesAdmin";
import tiles from "@/store/modules/tiles";
import graphs from "@/store/modules/graphs";
import eastereggs from "@/store/modules/eastereggs";
import alerts from "@/store/modules/alerts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    user,
    operation,
    packages,
    overview,
    navigation,
    productAdmin,
    devicesAdmin,
    tiles,
    graphs,
    eastereggs,
    alerts,
  },
});
